import React, { Fragment, useState } from "react";
import PropTypes from 'prop-types'; // Import PropTypes
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { useTmApprovedMutation } from "../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../Assets/loaders/loaders";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "60px",
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "30px",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "44px",
    border: "2px solid #8153A2",
};

const ApproveModal = ({ open, onClose, id }) => { // Add 'id' to props
    const navigate = useNavigate();
    const [approveSubmit] = useTmApprovedMutation();
    const [isLoading, setIsLoading] = useState(false);

    const postData = {
        tid: id[0],
        peid: id[1],
        ref: id[2],
        sts: "A"
    }

    // Approve Entity
    const approveEntity = (e) => {
        approveSubmit(postData)
            .then((success) => {
                if (success?.data?.statusCode === 200) {
                    setIsLoading(false);
                    toast.success(success.data.message, { autoClose: 3000 });
                    window.location.reload()
                } else if (success?.data?.statusCode > 300) {
                    toast.error(success?.data?.message);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error.message);
            });
    };
    const handleCloseModal = () => {
        if (localStorage.getItem("type") === "Entity") {
            navigate("/entitymanage-route");
        } else if (localStorage.getItem("type") === "Telemarketer") {
            navigate("/manage-route");
        }
        onClose();
    };
    

    if (!open) return null;
    return (
        <Fragment>
            {isLoading && <Loaders />}
            <Modal
                open={open}
                onClose={() => handleCloseModal()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps = {{
                    onClick: null, // Prevents closing when clicking outside the Modal
                    }}
            >
                <Box sx={style}>
                    <h1 className="modal-head">Are you sure you want to approve this Entity</h1>
                    <button className="login_btn cursor" onClick={() => approveEntity()}>
                        Approve
                    </button>
                    <button
                        className="cancel_btn cursor"
                        onClick={() => handleCloseModal()}
                    >
                        Cancel
                    </button>
                </Box>
            </Modal>
        </Fragment>
    );
}

// Add prop types validation
ApproveModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    id: PropTypes.array.isRequired, // Assuming 'id' is an array
};

export default ApproveModal;
