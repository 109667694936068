import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as imgs from "../../Assets/images";
import { useDispatch } from "react-redux";
import {
  // login as logining,
  logout,
  // selectAuth,
} from "../../features/login/authSlice";

const SelectorLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
    dispatch(logout());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const selectPartner = (value) => {
    switch (value) {
      case "Enterprise":
        navigate("/login", { state: value });
        break;
      case "Telemarketer":
        navigate("/login", { state: value });
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <div className="login_window">
        <div className="login text-center">
          <img src={imgs.mainLogo} alt="logo" />
          <h4>Select your Area of Operations to Login</h4>
          <div className="select">
            <span
              className="d-flex justify-content-start mb-2"
              onClick={() => selectPartner("Enterprise")}
            >
              <input type="radio" id="entity" name="Entity" className="me-2" />
              <label htmlFor="entity">Enterprise</label>
              <span className="material-symbols-outlined tooltip_info">info</span>
              <span className="tooltiptext">
                <li>
                  A person or legal entity dealing with goods or services as a
                  profession or trade, which is considered in terms of its
                  volume or profitability, who would like to communicate with
                  its customer(s) through SMS or voice through a Telemarketer.
                </li>
              </span>
            </span>

            <span
              className="d-flex"
              onClick={() => selectPartner("Telemarketer")}
            >
              <input
                type="radio"
                id="telemarketer"
                name="Telemarketer"
                className="me-2"
              />
              <label htmlFor="telemarketer">Telemarketer</label>
              <span className="material-symbols-outlined tooltip_info">info</span>
              <span className="tooltiptext">
                <li>
                  <b>Telemarketer Aggregator:</b> A person or legal entity who
                  doesn't have direct connectivity with telecom service
                  provider, but is engaged in the activity of aggregation of SMS
                  or voice communication (aggregate traffic from multiple
                  enterprises).
                </li>
                <li>
                  <b>Telemarketer Delivery:</b> A person or legal entity who has
                  direct connectivity with telecom service provider(s) and is
                  engaged in the activity of aggregation & delivery of SMS and
                  voice communication.
                </li>
              </span>
            </span>
          </div>
          <p className="m-0">
            A secured SMS Traceability platform to manage your Commercial
            communication nodes.
          </p>
        </div>
        <div className="text-center">
          <div className="d-flex mb-4 justify-content-center">
            <div className="me-5">
              <b>Powered By : </b>
              <img width="150px" src={imgs.teledgerslogo} alt="logo" />
            </div>
            <div className="">
              <b>Operator Partner : </b>
              <img height="56px" src={imgs.smartPinglogo} alt="logo" />
            </div>
          </div>
          <p>
            “Complies with the direction from TRAI dated 16th February 2023 to
            ensure traceability of supply chain
            <br />
            between principal entities and the Telemarketers involved in the SMS
            delivery process”
          </p>
        </div>
      </div>
    </div>
  );
};

export default SelectorLogin;
