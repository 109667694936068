const resCodes = {
    "5101": "Category is 0 or 50.",
    "5103": "Subscriber cmode is 10 or 12.",
    "5104": "Opstype is active in DLT",
    "5105": "day code found in Subscriber Preference Day",
    "5106": "Time code found in Subscriber Preference Time",
    "5107": "User is not reg. on DND but currently restricted (Blackout)",
    "5108": "Opstype is T",
    "5109": "PREF_CAT_BLOCKED(blocked pref ctgr matches witcont. ctgr",
    "5201": "Header is not Active",
    "5202": "Header is Blacklisted.",
    "5203": "Header is not found/registered",
    "5204": "HDR_OP_INACTIVE",
    "5205": "Entity ID not matched with XML",
    "5206": "Port is Promo getting Trans hits",
    "5207": "Port is Trans getting Promo hits",
    "5208": "HDR_CASE_SEN_CLI (Govt. Header)",
    "5210": "Entity ID not matching with desire User_ID",
    "5213": "Suspended due to usage",
    "5214": "Suspended due to validity",
    "5215": "Customer initiated suspension",
    "5301": "Content Unregistered",
    "5302": "Content Blacklisted",
    "5303": "Content with Unknown CLI",
    "5304": "Content Not in DB",
    "5305": "Content Type Mismatched",
    "5306": "Content ID not Active",
    "5307": "Content Regex not matched",
    "5308": "Content ID Blank",
    "5309": "Content ID Blacklisted by VISPL",
    "5310": "Content Multipart not complete",
    "5311": "Content Active/Inactive by VM/QL",
    "5312": "Content variable exceeds",
    "5313": "Suspended due to usage",
    "5314": "Suspended due to validity",
    "5315": "Customer initiated suspension",
    "4000": "Redis Error",
    "4001": "Redis request Timeout",
    "4002": "Not found in Redis DB",
    "4004": "Content Id not found in Redis DB",
    "5401": "COMM is Trans/SE/SI & Header is Inactive/Blacklisted",
    "5402": "COMM is Trans/SE/SI & Content_ID is Inactive",
    "5403": "COMM is Trans/SE/SI & Content AssoC. Header is Different",
    "5404": "COMM is Trans&Content is registered for some other type",
    "5405": "COMM is SI & Content is registered for some other type",
    "5406": "COMM is SE & Content is registered for some other type",
    "5501": "Consent Not in DB",
    "5502": "Consent Expired",
    "5503": "Consent Revoked",
    "5504": "Consent Initiated",
    "5505": "Consent_Verified",
    "5506": "Consent_Failed",
    "5507": "Consent_Disconnected",
    "5508": "Consent_SBchurn",
    "5510": "Migration-Verified",
    "5601": "Chain does not match" ,
    "5602": "TM ID does not match",
    "5603": "Secret key does not match",
    "5604": "URL is not valid",
    "7001": "Entity is In-Active",
    "7002": "Entity is Blacklist",
    "7003": "Entity not in DB",
    "7004": "Entity ID Blank",
    "7005": "ENTITY_OP_INACTIVE"
}
export default resCodes;