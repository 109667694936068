import React, { useEffect, useState } from "react";
import {
  usePeGetTMLISTMutation,
  usePeSubmitBindingRequestMutation
} from "../../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EntityPrivateLayout } from "../../../layouts/EntityPrivateLayout";
import { useNavigate } from "react-router-dom";
import Loaders from "../../../Assets/loaders/loaders";

const EntityBindingPartner = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState("");
  const [viewDetails, setViewDetails] = useState(false);
  const [productDetails] = usePeGetTMLISTMutation();

  const [containData, setContainData] = useState({})
  const [fileData, setFileData] = useState([]);
  const [checkagree, setCheckAgree] = useState(false)
  const [submitRequest] = usePeSubmitBindingRequestMutation();
  const navigate = useNavigate();

  const postData = { "id": id }

  useEffect(() => {
    if (localStorage.getItem("type") !== "Enterprise") {
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const viewDetail = () => {
    if (id === "") {
      toast.error("Please Provide TM ID")
    } else {
      setIsLoading(true);
      productDetails(postData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setIsLoading(false);
            toast.success(success.data.message, { autoClose: 2000 });
            setViewDetails(true);
            setContainData(success?.data?.data)
          } else if (success?.data?.statusCode > 200) {
            setIsLoading(false);
            setViewDetails(false);
            toast.error(success?.data?.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setViewDetails(false);
          toast.error(error.message);
        });
    }
  };


  const submitPostData =
  {
    "tid": containData[0]?.id,
    "tname": containData[0]?.name,
    "peid": localStorage.getItem("id"),
    "pename": localStorage.getItem("name"),
    "approvalDoc": fileData,
    "peuserid": localStorage.getItem("email")
  }
  const submit = () => {
    if (!fileData.length > 0) {
      toast.error("Please Upload the file")
    }
    else if (checkagree === false) {
      toast.error("Please Check the agreement")
    }
    else {
      setIsLoading(true);
      submitRequest(submitPostData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setIsLoading(false);
            toast.success(success?.data?.message, { autoClose: 2000 });
            setTimeout(() => {
              navigate("/entitymanage-route");
            }, 2000);
          }
          else if (success?.data?.Status > 200) {
            setIsLoading(false);
            toast.error(success?.data?.message);
          }
          else if (success?.data?.statusCode > 200) {
            setIsLoading(false);
            toast.error(success?.data?.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.message);
        });
    }
  };

  const goBack = () => {
    navigate("/entitymanage-route");
  }



  const fileInput = (e) => {
    const formData = new FormData();
    formData.append(`doc`, e.target.files[0]);

    fetch(`${process.env.REACT_APP_API_PORT_DLT}/entity/uploadFile`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setFileData(data.data)
      })
      .catch((err) => toast.error("Error", err));
  };




  return (
    <EntityPrivateLayout>
      {isLoading && <Loaders />}
      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <div className="top_border_vlt">
          <h5 className="ms-auto m-0">Binding Request</h5>
          <span
            className="material-symbols-outlined cursor ms-auto"
            onClick={() => goBack()}
          >
            arrow_back
          </span>
        </div>

        <div className="binding_col mt-5">
          <div className="d-flex align-items-center mb-3 col-md-6">
            <div className="col-md-5">
              TM ID (DLT Registration ID) :{" "}
            </div>
            <div className="searchBar col-md-7">
              <input
                id="searchQueryInput"
                type="text"
                name="searchQueryInput"
                placeholder="Enter TM ID"
                style={{ background: "#F8F8F9" }}
                defaultValue={id}
                onChange={(e) => setId(e.target.value)}
              />
              {/* <button
                id="searchQuerySubmit"
                type="submit"
                name="searchQuerySubmit"
              >
                <span>
                  <svg
                    style={{ width: "24px", height: "24px" }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#8153A2"
                      d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                    />
                  </svg>
                </span>
              </button> */}
            </div>
          </div>

          <span className="d-flex justify-content-center">
            <button
              className="login_btn cursor"
              style={{ width: "100%" }}
              onClick={() => {
                viewDetail();
              }}
            >
              View Details
            </button>
          </span>
        </div>

        {viewDetails === true ? (
          <div className="binding_col mt-4">
            <table className="withBorder">
              <thead>
                <tr>
                  <td><b>Organization Name</b></td>
                  <td>{containData[0].name}</td>
                </tr>
                <tr>
                  <td><b>Registration ID</b></td>
                  <td>{containData[0].id}</td>
                </tr>
              </thead>
            </table>

            <div className="d-flex flex-column align-items-center m-4">
              <p className="mb-3" style={{color:"red"}}>Upload Letter of Authorization *</p>
              <input className="ms-auto" type="file" style={{ background: "#F8F8F9" }} onChange={(e) => fileInput(e)} />

              <p className="enhancements d-flex align-self-center mt-2">
                <input id="#agreement" type="checkbox" onClick={(e) => setCheckAgree(e.target.checked)} />
                <label htmlFor="agreement">I agree to the terms of agreement.</label>
              </p>

              <button
                className="login_btn cursor mt-4"
                style={{ width: "60%" }}
                onClick={() => {
                  submit();
                }}
              >
                Submit Request
              </button>
            </div>

          </div>
        ) : (
          <div></div>
        )}
      </div>
    </EntityPrivateLayout>
  );
};

export default EntityBindingPartner;
