import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { antiphishingServices } from '../Services/services'
import { authReducer } from '../features/login/authSlice'
import { adminAuthReducer } from '../features/login/adminAuthSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    auth: adminAuthReducer,
    [antiphishingServices.reducerPath]: antiphishingServices.reducer
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(antiphishingServices.middleware),
})

setupListeners(store.dispatch)



