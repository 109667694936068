import React, { useState, useEffect } from "react";
import * as imgs from "../../Assets/images";
import {
  useDashboardSummaryMutation,
  // useListMapMutation,
  useDashCountMutation,
  useDashboardSMSMutation,
} from "../../Services/services";
// import GraphChart from "../../Utils/charts";
// import PieChartPurple from "../../Utils/piechart";
import { EntityPrivateLayout } from "../../layouts/EntityPrivateLayout";
import Loaders from "../../Assets/loaders/loaders";
import Counter from "../../Components/counter";
import { useNavigate } from "react-router-dom";

const EntityDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // table data API
  // let [getListMap] = useListMapMutation();
  let [dashCount] = useDashCountMutation();
  let [dashSMS] = useDashboardSMSMutation();

  // let [tableData, setTableData] = useState([]);

  let [summaryRender] = useDashboardSummaryMutation();
  let [dashboardSummary, setDashboardSummary] = useState({
    // url
    total: 0,
    whitelist: 0,
    blacklist: 0,
    active: 0,
    blocked: 0,
    complaints: 0,
    // IP
    totalIP: 0,
    activeIP: 0,
    blockedIP: 0,
    // sms
    sms: 0,
    routes: 0,
    activeRoutes: 0,
    blockedRoutes: 0,
    //tm
    telemark: 0,
    telemarkActive: 0,
    telemarkDeactive:0,
  });
  let [headers, setHeaders] = useState(0);
  let [templates, setTemplates] = useState(0);
  let [sms, setSms] = useState(0);

  /* TY SAMPLE USEEFFECT */

  useEffect(() => {
    if (localStorage.getItem("type") !== "Enterprise") {
      navigate("/");
    } else {
      const dashUrlCount = () => {
        setIsLoading(true);
        summaryRender()
          .then((success) => {
            if (success?.data?.statusCode === 200) {
              setIsLoading(false);
              setDashboardSummary({
                ...dashboardSummary,
                total: success.data.data.totalCount[0].value,
                whitelist: success.data.data.urlTypeCounts[1].count,
                blacklist: success.data.data.urlTypeCounts[0].count,
                active : success.data.data.statusCounts[0].count,
                blocked: success.data.data.statusCounts[1].count,
                complaints: success.data.data.totalUccCount[0].value,
                totalIP: success.data.data.totalIpCount[0].value,
                activeIP: success.data.data.statusIpCounts[0].count,
                blockedIP: success.data.data.statusIpCounts[1].count,
                // sms: success.data.data.totalsmsCount[0].value,
                routes: success.data.data.totalRoutesCount[0].value,
                activeRoutes: success.data.data.statusRoutesCounts[0].count,
                blockedRoutes: success.data.data.statusRoutesCounts[1].count,
                telemark: success.data.data.totalTmCount[0].value,
                telemarkActive: success.data.data.statusTmCounts[0].count,
                telemarkDeactive: success.data.data.statusTmCounts[1].count,
              });
            } else if (success.error.data.statusCode > 300) {
              setIsLoading(false);
            } else if (success.data.statusCode > 300) {
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setIsLoading(false);
          });
        };
        const onlySMS = () => {
          setIsLoading(true);
          const postData = {
            peid: localStorage.getItem("id"),
          };
          dashSMS(postData)
          .then((success) => {
            if (success?.data?.statusCode === 200) {
              setIsLoading(false);
              setSms(success.data.data[0].total_count);
            } else if (success.error.data.statusCode > 300) {
              setIsLoading(false);
            } else if (success.data.statusCode > 300) {
              setIsLoading(false);
              }
            })
            .catch((error) => {
              setIsLoading(false);
            });
          };
      const dashHeader = () => {
        const postData = {
          dbName: "header",
          peid: localStorage.getItem("id"),
        };
        setIsLoading(true);
        dashCount(postData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setIsLoading(false);
            setHeaders(success.data.data.A);
          } else if (success.error.data.statusCode > 300) {
            setIsLoading(false);
          } else if (success.data.statusCode > 300) {
            setIsLoading(false);
            }
          })
          .catch((error) => {
            setIsLoading(false);
          });
        };
      const dashTemplate = () => {
        const postData = {
          dbName: "template",
          peid: localStorage.getItem("id"),
          type: "CT",
        };
        setIsLoading(true);
        dashCount(postData)
        .then((success) => {
            if (success?.data?.statusCode === 200) {
              setIsLoading(false);
              setTemplates(success.data.data.A);
            } else if (success.error.data.statusCode > 300) {
              setIsLoading(false);
            } else if (success.data.statusCode > 300) {
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setIsLoading(false);
          });
      };
      
      // const handleDomains = () => {
        //   setIsLoading(true);
        //   getListMap()
      //     .then((success) => {
        //       if (success?.data?.statusCode === 200) {
          //         setIsLoading(false);
      //         setTableData(success.data.data);
      //       } else if (success.error.data.statusCode > 300) {
      //         setIsLoading(false);
      //       } else if (success.data.statusCode > 300) {
      //         setIsLoading(false);
      //       }
      //     })
      //     .catch((error) => {
      //       setIsLoading(false);
      //     });
      // };

      dashUrlCount();
      dashHeader();
      dashTemplate();
      onlySMS();
      // handleDomains();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <EntityPrivateLayout>
      {isLoading && <Loaders />}

      <div className="data_layout_2">
        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Total URL Submitted</p>
              <h1 className="headCount">
                <Counter number= {dashboardSummary.total}/>
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          <div className="d-flex justify-content-end">
            {/* <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Active URLs"
            >
              <span className="material-symbols-outlined">check_circle</span>
              <p><Counter number={dashboardSummary.active} /></p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Inactive URLs"
              >
              <span className="material-symbols-rounded">cancel</span>
              <p><Counter number={dashboardSummary.blocked} /></p>
            </span> */}
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Whitelisted URLs"
            >
              <span className="material-symbols-rounded">add_circle</span>
              <p>  <Counter number={dashboardSummary.whitelist} /></p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Blacklisted URLs"
            >
              <span className="material-symbols-rounded">block</span>
              <p><Counter number={dashboardSummary.blacklist} /></p>
            </span>
          </div>
        </div>
        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Total Headers</p>
              <h1 className="headCount"><Counter number={headers} /></h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
        </div>
        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Total Templates Registered</p>
              <h1 className="headCount"><Counter number={templates} /></h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
        </div>
      </div>
      <div className="data_layout_2">
        
        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Total IP</p>
              <h1 className="headCount"><Counter number={dashboardSummary.totalIP} /></h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Whitelisted IPs"
            >
              <span className="material-symbols-rounded">add_circle</span>
              <p><Counter number={dashboardSummary.activeIP} /></p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Blacklisted IPs"
            >
              <span className="material-symbols-rounded">block</span>
              <p><Counter number={dashboardSummary.blockedIP} /></p>
            </span>
          </div>
        </div>

        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Total SMS</p>
              <h1 className="headCount"><Counter number={sms} /></h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
        </div>

        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Routes</p>
              <h1 className="headCount"><Counter number={dashboardSummary.routes} /></h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Active Routes"
            >
              <span className="material-symbols-outlined">check_circle</span>
              <p><Counter number={dashboardSummary.activeRoutes} /></p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Inactive Routes"
            >
              <span className="material-symbols-rounded">cancel</span>
              <p><Counter number={dashboardSummary.blockedRoutes} /></p>
            </span>
          </div>
        </div>

      </div>


      <div className="data_layout_2">

      <div className="data_box w-33">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">UCC Complaints</p>
              <h1 className="headCount"><Counter number={dashboardSummary.complaints} /></h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
        </div>

        <div className="data_box w-33">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Linked Telemarketers</p>
              <h1 className="headCount">
                <Counter number={dashboardSummary.telemark} />
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Active"
            >
              <span className="material-symbols-outlined">check_circle</span>
              <p>
                <Counter number={dashboardSummary.telemarkActive} />
              </p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Inactive"
            >
              <span className="material-symbols-rounded">cancel</span>
              <p>
                <Counter number={dashboardSummary.telemarkDeactive} />
              </p>
            </span>
          </div>
        </div>
        
      </div>
    </EntityPrivateLayout>
  );
};

export default EntityDashboard;
