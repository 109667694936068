import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import PropTypes from 'prop-types';
import * as imgs from "../Assets/images";
import { useTmAddIPMutation } from "../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from 'uuid'; // for generating unique keys

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "560px",
  padding: "60px",
  display: "inline-flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "30px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "44px",
  border: "2px solid #8153A2",
};

export default function Modal3({ open, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [type, setType] = useState("whitelisted");
  const [addURL] = useTmAddIPMutation();

  const [ipAddress, setIPAddress] = useState(['', '', '', '']);
  const inputRefs = useRef(Array.from({ length: 4 }).map(() => React.createRef()));
  const ipKeys = useRef(Array.from({ length: 4 }).map(() => uuidv4())); // generate unique keys

  const handleInputChange = (index, event) => {
    const formattedValue = event.target.value.replace(/[^\d]/g, '').slice(0, 3);

    setIPAddress((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = formattedValue;
      return newValues;
    });

    if (index < 3 && formattedValue.length === 3) {
      inputRefs.current[index + 1]?.current?.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && !event.target.value) {
      inputRefs.current[index - 1]?.current?.focus();
    }
  };

  const urlData = {
    "ipType": type,
    "ip": ipAddress.join('.')
  };

  const submitURL = () => {
    if (ipAddress.every(element => element === '') || !isValidIPv4(ipAddress.join('.'))) {
      toast.error('Invalid IP');
    } else {
      setIsLoading(true);
      addURL(urlData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setIsLoading(false);
            toast.success(success.data.message, { autoClose: 1000 });
            setOpenSecondModal(true);
          } else if (success?.data?.statusCode > 300) {
            setIsLoading(false);
            toast.error(success?.data?.message);
          } else if (success?.error?.status > 300) {
            setIsLoading(false);
            toast.error(success?.error?.data?.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.message);
        });
    }
  };

  const isValidIPv4 = (str) => {
    const ipv4Pattern = /^(\d{1,3}\.){3}\d{1,3}$/;
    return ipv4Pattern.test(str);
  };

  const handleCloseModal = () => {
    setActive(false);
    setType("whitelisted");
    setOpenSecondModal(false);
    setIPAddress(['', '', '', '']);
    onClose();
  };

  if (!open) return null;
  return (
    <>
      {!isLoading ? (
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            onClick: null,
          }}
        >
          {!openSecondModal ? (
            <Box sx={style}>
              <h1 className="modal-head">Add IP as Whitelisted/Blacklisted</h1>

              <span className="url_toggles">
                <button
                  value="whitelisted"
                  className={active === false ? " active url_btn" : "url_btn"}
                  onClick={(e) => {
                    setActive(false);
                    setType(e.target.value);
                  }}
                >
                  Whitelisted IP
                </button>
                <button
                  value="blacklisted"
                  className={active === true ? "active url_btn " : "url_btn"}
                  onClick={(e) => {
                    setActive(true);
                    setType(e.target.value);
                  }}
                >
                  Blacklisted IP
                </button>
              </span>

              <div className="ip-input-container">
                <label htmlFor="ipv4">IP Address</label>
                <div className="ip-input-box">
                  {ipKeys.current.map((key, index) => (
                    <div key={key} className="ip-group">
                      <input
                        ref={inputRefs.current[index]}
                        id={`ip-input-${index}`}
                        type="tel"
                        pattern="[0-9]*"
                        className="ip-input"
                        maxLength="3"
                        value={ipAddress[index]}
                        onChange={(event) => handleInputChange(index, event)}
                        onKeyDown={(event) => handleKeyDown(index, event)}
                      />
                      {index < 3 && <span className="ip-decimal">.</span>}
                    </div>
                  ))}
                </div>
              </div>
              <button className="login_btn cursor" onClick={submitURL}>
                Send Request
              </button>
              <button
                className="cancel_btn cursor"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
            </Box>
          ) : (
            <Box sx={style}>
              <h1 className="modal-head">Add IP Request Sent Successfully</h1>
              <img src={imgs.modalBg} alt="done" />
              <p>
                Your IP: <span style={{ fontWeight: "100" }}>{ipAddress.join('.')}</span> has been {type} successfully
              </p>
              <button
                className="cancel_btn cursor"
                onClick={handleCloseModal}
              >
                Done
              </button>
            </Box>
          )}
        </Modal>
      ) : (
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            onClick: null,
          }}
        >
          <Box sx={style}>
            <h1 className="modal-head">Add IP Request Sent Successfully</h1>
            <img src={imgs.modalBg} alt="done" />
            <p>
              {type === "whitelisted" ? "Whitelisted" : "Blacklisted"} IP Adding...
            </p>
          </Box>
        </Modal>
      )}
    </>
  );
}

Modal3.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
