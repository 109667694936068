import React, { useState, useEffect } from "react";
import { useAdminUrlListMutation } from "../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../../Assets/loaders/loaders";
import { AdminPrivateLayout } from "../../layouts/AdminPrivateLayout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const AdminURLInside = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { value } = useParams();
  const { id } = useParams();
  const navigate = useNavigate();

  const [urlList] = useAdminUrlListMutation();

  let [urls, setUrls] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("type") !== "ADMIN") {
      navigate("/admin-login")
    } else
      handleDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleDomains = () => {
    let postData = {
      peid: id,
      urlType: value,
      search: "",
    };
    setIsLoading(true);
    urlList(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          setUrls(success.data.data.urlRegistationList);
        } else if (success.error.data.statusCode > 300) {
          setIsLoading(false);
        } else if (success.data.statusCode > 300) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  // let [enable] = useEnableIPMutation();
  // const handleToggleStatus = (rowId, currentStatus) => {
  //   const newStatus = currentStatus === "ACTIVE" ? "DISABLED" : "ACTIVE";
  //   const postData = {
  //     _id: rowId,
  //   };
  //   setIsLoading(true);
  //   enable(postData)
  //     .then((success) => {
  //       if (success?.data?.statusCode === 200) {
  //         setIsLoading(false);
  //         toast.success(
  //           `${newStatus === "ACTIVE" ? "Enabled" : "Disabled"} successfully`
  //         );
  //         handleDomains();
  //       } else if (success.error.data.statusCode > 300) {
  //         setIsLoading(false);
  //       } else if (success.data.statusCode > 300) {
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       toast.error(error.message);
  //     });
  // };


  let handleTableRow = (row, index) => {
    if (value === "whitelisted") {
      return (
        <tr key={index}>
          <td>{++index}</td>
          <td>{row.url}</td>
          <td>{new Date(row.createdAt).toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} {new Date(row.createdAt).getFullYear()}, {new Date(row.createdAt).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
          <td>{new Date(row.updatedAt).toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} {new Date(row.updatedAt).getFullYear()}, {new Date(row.updatedAt).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
          {/* <td>
            {row.status === "ACTIVE" ? <span style={{ color: "#30ad23" }}>ACTIVE</span> : <span style={{ color: "#ed1c36" }}>BLOCK</span>}
          </td> */}
        </tr>
      );
    } else {
      return (
        <tr key={index}>
          <td>{++index}</td>
          <td>{row.url}</td>
          <td>{new Date(row.createdAt).toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} {new Date(row.createdAt).getFullYear()}, {new Date(row.createdAt).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
          <td>{new Date(row.updatedAt).toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} {new Date(row.updatedAt).getFullYear()}, {new Date(row.updatedAt).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
        </tr>
      );
    }
  };

  const goBack = () => {
    navigate("/admin-url");
  };
  return (
    <AdminPrivateLayout>
      {isLoading && <Loaders />}

      {/* ------------------------------------data table----------------------------------- */}
      {value === "whitelisted" ? (
        <div className="data_layout_4">
          <div className="top_border_vlt d-flex justify-content-center">
            <h5 className="ms-auto m-0">Whitelisted URLs</h5>
            <span
              className="material-symbols-outlined cursor ms-auto"
              onClick={() => goBack()}
            >
              arrow_back
            </span>
          </div>
          <div className="table_fixhead max">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>URLs</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  {/* <th>URL Status</th> */}
                </tr>
              </thead>
              <tbody>
                {urls.length > 0 ? (
                  urls.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td
                      colSpan="100%"
                      style={{
                        textAlign: "center",
                        paddingTop: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="data_layout_4">
          <div className="top_border_vlt d-flex justify-content-center">
            <h5 className="ms-auto m-0">Blacklisted URLs</h5>
            <span
              className="material-symbols-outlined cursor ms-auto"
              onClick={() => goBack()}
            >
              arrow_back
            </span>
          </div>
          <div className="table_fixhead">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>URLs</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                </tr>
              </thead>
              <tbody>
                {urls.length > 0 ? (
                  urls.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td
                      colSpan="100%"
                      style={{
                        textAlign: "center",
                        paddingTop: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </AdminPrivateLayout>
  );
};

export default AdminURLInside;
