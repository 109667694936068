import React, { useState, useEffect } from "react";
import {
  useListMapMutation,
  // useEnableMapMutation,
  useDeleteMapMutation,
} from "../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EntityPrivateLayout } from "../../layouts/EntityPrivateLayout";
import Modal2 from "../../Components/modal_mapping";
import Modal2Bulk from "../../Components/entityBulkMAP";
import Loaders from "../../Assets/loaders/loaders";
import { useNavigate } from "react-router-dom";

const EntityScrubbing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let [getListMap] = useListMapMutation();
  // let [enable] = useEnableMapMutation();
  let [del] = useDeleteMapMutation();

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const [openModal2, setOpenModal2] = useState(false);
  const handleOpenModal2 = () => setOpenModal2(true);
  let [tableData, setTableData] = useState([]);


  useEffect(() => {
    if (localStorage.getItem("type") !== "Enterprise") {
      navigate("/")
    } else
      handleDomains();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, openModal2]);

  let handleDomains = () => {
    setIsLoading(true);
    getListMap()
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          setTableData(success.data.data);
        } else if (success.error.data.statusCode > 300) {
          toast.error(success.error.data.message);
          setIsLoading(false);
        } else if (success.data.statusCode > 300) {
          toast.error(success?.data?.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  let handleTableRow = (row, index) => {
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row.templateId}</td>
        <td>{row.url}</td>
        <td>{new Date(row.createdAt).toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} {new Date(row.createdAt).getFullYear()}, {new Date(row.createdAt).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
        {/* <td>
          <label className="switch">
            <input
              type="checkbox"
              checked={row.status === "ACTIVE"}
              onClick={() => {
                endis(row._id, row.status);
              }}
            />
            <span className="slider round"></span>
          </label>
        </td> */}
        <td>
          <span
            className="material-symbols-outlined"
            style={{ cursor: "pointer", color: "#E74E37" }}
            onClick={() => handleDeleteClick(row._id)}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Delete"
          >
            delete
          </span>
        </td>
      </tr>
    );
  };

  // const endis = (id, status) => {
  //   const newStatus = status === "ACTIVE" ? false : true;
  //   const postData = {
  //     _id: id,
  //   };
  //   setIsLoading(true);
  //   enable(postData)
  //     .then((success) => {
  //       if (success?.data?.statusCode === 200) {
  //         setIsLoading(false);
  //         toast.success(
  //           `${newStatus === true ? "Enabled" : "Disabled"} successfully`
  //         );
  //         handleDomains();
  //       } else if (success.error.data.statusCode > 300) {
  //         setIsLoading(false);
  //       } else if (success.data.statusCode > 300) {
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       toast.error(error.message);
  //     });
  // };

  const handleDeleteClick = (rowId) => {
    const postData = {
      _id: rowId,
    };
    setIsLoading(true);
    del(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          toast.success(success.data.message);
          // setEditableRowIndex(null);
          handleDomains();
        } else if (success.error.data.statusCode > 300) {
          setIsLoading(false);
        } else if (success.data.statusCode > 300) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };
  return (
    <EntityPrivateLayout>
      {isLoading && <Loaders />}
      <div className="mt-3 Dash_header">
        
        <span className="add_btn cursor ms-auto me-3 glow-on-hover" onClick={handleOpenModal}>
          <span className="material-symbols-outlined btn_anim">add_circle</span>
          <p>Map URL</p>
        </span>
        <span className="add_btn cursor glow-on-hover" onClick={handleOpenModal2}>
          <span className="material-symbols-outlined btn_anim">add_circle</span>
          <p>Map Bulk URLs</p>
        </span>
      </div>

      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <span className="top_border_vlt">
          <p>Mapped URLs</p>
        </span>
        <div className="table_fixhead">
          <table>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Template ID</th>
                <th>URL</th>
                <th>Time Stamp</th>
                {/* <th>Disable/Enable</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                tableData.map((row, index) => handleTableRow(row, index))
              ) : (
                <tr>
                  <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* model calling */}
      <Modal2 open={openModal} onClose={() => setOpenModal(false)} />
      <Modal2Bulk open={openModal2} onClose={() => setOpenModal2(false)} />

    </EntityPrivateLayout>
  );
};

export default EntityScrubbing;
