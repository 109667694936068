import React from 'react'
import { DashHeader, Sidebar } from './AdminPublic'
import PropTypes from 'prop-types';

export const AdminPrivateLayout = ({ children }) => {
 
  
  return (
    <div className='dashboard'>
  
      <div className='main_window'>
          <Sidebar/>
          <div className="data_window" >
            <div className='dashboard_window'>
              <DashHeader/>  
              
              {children}
            </div>
          </div>
      </div>
      <div className='footer'>Powered by : Teledgers Technologies Pvt. Ltd.</div>
    </div>
  )
}

AdminPrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
};