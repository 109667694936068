import React, { useState, useEffect } from "react";
import {
  useTmListCallbackMutation
} from "../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../../Assets/loaders/loaders";
import { PrivateLayout } from "../../layouts/PrivateLayout";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Callback = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  let [tableData, setTableData] = useState([]);

  let [getListCallback] = useTmListCallbackMutation();


  const [isHovered, setIsHovered] = useState(false);


  useEffect(() => {
    if (localStorage.getItem("type") !== "Telemarketer") {
      navigate("/")
    } else
      handleDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleDomains = () => {
    let postData = {
      "peid": "",
      "number": ""
    }
    setIsLoading(true);
    getListCallback(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          setTableData(success.data.data);
        } else if (success.error.data.statusCode > 300) {
          toast.error(success.error.data.message);
          setIsLoading(false);
        } else if (success.data.statusCode > 300) {
          toast.error(success?.data?.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  const handleMouse = (e, type, rowId) => {
    if (e.type === "mouseenter") {
      setIsHovered(`${type}-${rowId}`);
    } else {
      setIsHovered("");
    }
  };

  let handleTableRow = (row, index) => {
    const rowId = row._id; // Assuming _id is unique for each row
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row._id}</td>
        <td>{row.pename}</td>
        <td
          onMouseEnter={(e) => handleMouse(e, "white", rowId)}
          onMouseLeave={(e) => handleMouse(e, "white", rowId)}
          id={`whitelisted-${rowId}`}
        >
          {isHovered === `white-${rowId}` ? (
            <Link to={`/callback/${rowId}`}>
              <button className="view_btn">View more...</button>
            </Link>
          ) : (
            row.totalCount
          )}
        </td>
      </tr>
    );
  };
  return (
    <PrivateLayout>
      {isLoading && <Loaders />}
      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <span className="top_border_vlt">
          <p>Callback Number List</p>
        </span>
        <div className="table_fixhead max">
          <table>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Entity ID</th>
                <th>Entity Name</th>
                <th>Numbers Registered</th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                tableData.map((row, index) => handleTableRow(row, index))
              ) : (
                <tr>
                  <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </PrivateLayout>
  );
};

export default Callback;
