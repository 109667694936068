import React, { useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for props validation
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectAuth, login } from '../features/login/adminAuthSlice';

const AdminAuthGuard = ({ children }) => {
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve authentication state from storage
    const storedAuthState = JSON.parse(localStorage.getItem('authState'));

    if (storedAuthState) {
      // Dispatch login action with stored authentication state
      dispatch(login(storedAuthState.user));
    } else {
      // Redirect to the home page if not authenticated
      navigate('/admin-login');
    }
  }, [auth.isAuthenticated, dispatch, navigate]);

  // Render children only if authenticated
  return auth.isAuthenticated ? <>{children}</> : null;
};

// Add prop types validation
AdminAuthGuard.propTypes = {
  children: PropTypes.node, // Children should be a valid React node
};

export default AdminAuthGuard;
