import React, { useState, useEffect } from "react";
import { PrivateLayout } from "../../layouts/PrivateLayout";
import {
  useTmBindingSearchByCharacterMutation,
  usePeRouteHashListMutation
} from "../../Services/services";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Loaders from "../../Assets/loaders/loaders";
import ApproveModal from "../../Components/modal_approve";
import RejectModal from "../../Components/modal_reject";
import { toast } from "react-toastify";
import ModalPrivateKey from "../../Components/modal_private_key";
import copy from "copy-to-clipboard";


const Binding = () => {
  const [isLoading, setIsLoading] = useState(false);

  let [getData] = useTmBindingSearchByCharacterMutation();
  const navigate = useNavigate();

  const [isToggle, setIsToggle] = useState("route");
  const [filterValue/* , setFilterValue */] = useState("R")
  const handleOpenModal = () => { navigate("/manage-route/bindingpartner") }
  const [tableData, setTableData] = useState([])
  const [openModal, setOpenModal] = useState(false);
  const [id, setID] = useState([])
  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [keyTmId, setKeyTMId] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [hastList] = usePeRouteHashListMutation();
  const [hashData, setHashData] = useState([]);
  const [search, setSearch] = useState("")


  useEffect(() => {
    if (localStorage.getItem("type") !== "Telemarketer") {
      navigate("/")
    } else {
      if (isToggle === "route") {
        hash();
      } else
        handleDomains();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filterValue, isToggle, approveModal, rejectModal]);

  // const selectFilter = (e) => {
  //   setFilterValue(e.target.value);
  // };

  const hash = () => {
    hastList()
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          // toast.success(success.data.message, { autoClose: 3000 });
          setHashData(success.data.data)
        } else if (success?.data?.statusCode > 300) {
          // toast.error(success?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  let handleDomains = () => {
    let postData = {};
    if (isToggle === "rejected") {
      postData = {
        id: localStorage.getItem("id"),
        rqType: isToggle,
        search: search,
        dbName: "enrollment",
        accSort: -1,
        limit: 25,
        pageNo: 1,
        sts: filterValue,
      };
    } else {
      postData = {
        id: localStorage.getItem("id"),
        rqType: isToggle,
        search: search,
        dbName: "enrollment",
        accSort: -1,
        limit: 25,
        pageNo: 1,
      };
    }

    setIsLoading(true);
    getData(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          // toast.success(success.data.message, { autoClose: 3000 });
          setTableData(success.data.data)
        } else if (success?.data?.statusCode > 300) {
          setTableData("")
          // toast.error(success?.data?.message);
          setIsLoading(false);
        } else if (success.error.data.statusCode > 300) {
          setTableData("")
          setIsLoading(false);
          // toast.error(success.error.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        // toast.error(error.message);
      });
  };


  const copyText = (privateKey) => {
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 265);

    copy(privateKey);
    toast.success(`ID Copied: "${privateKey}"`);
  };


  const renderRequestedRow = (row, index) => (
    <tr key={index} style={{ textAlign: "center" }}>
      <td>{++index}</td>
      <td>{row.peid}</td>
      <td>{row.pename}</td>
      <td>
        <a href={row.approvalDoc} target="blank" className="ms-5">
          <span className="material-symbols-outlined">note_stack</span>
        </a>
      </td>
      <td>
        {new Date(row.cts * 1000)
          .toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
          .replace(/,/, '')}
      </td>
      <td>
        {row.sts === "P" && <span>Pending</span>}
        {row.sts === "A" && <span style={{ color: "#30ad23" }}>Active</span>}
        {row.sts === "R" && <span>Rejected</span>}
        {row.sts === "BK" && <span>Blocked</span>}
        {row.sts === "B" && <span>Blacklisted</span>}
        {row.sts === "I" && <span>Inactive</span>}
      </td>
    </tr>
  );

  const renderApprovedRow = (row, index) => (
    <tr key={index}>
      <td>{++index}</td>
      <td>{row.peid === localStorage.getItem("id") ? row.tid : row.peid}</td>
      <td>{row.pename === localStorage.getItem("name") ? row.tname : row.pename}</td>
      <td>
        <a href={row.approvalDoc} target="blank" className="ms-5">
          <span className="material-symbols-outlined">note_stack</span>
        </a>
      </td>
      <td>
        {new Date(row.cts * 1000)
          .toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
          .replace(/,/, '')}
      </td>
      <td>
        {row.sts === "P" && <span style={{ color: "#8153A2" }}>Pending</span>}
        {row.sts === "A" && <span style={{ color: "#30AD23" }}>Active</span>}
        {row.sts === "R" && <span style={{ color: "#ED1C36" }}>Rejected</span>}
        {row.sts === "BK" && <span style={{ color: "#fff", background: "black" }}>Blocked</span>}
        {row.sts === "B" && <span style={{ color: "#211031" }}>Blacklisted</span>}
        {row.sts === "I" && <span style={{ color: "#f8f8f9" }}>Inactive</span>}
      </td>
      <td>{row.hashId}</td>
    </tr>
  );

  const renderRejectedRow = (row, index) => (
    <tr key={index}>
      <td>{++index}</td>
      <td>{row.peid}</td>
      <td>{row.pename}</td>
      <td>
        <a href={row.approvalDoc} target="blank" className="ms-5">
          <span className="material-symbols-outlined">note_stack</span>
        </a>
      </td>
      <td>
        {new Date(row.cts * 1000)
          .toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
          .replace(/,/, '')}
      </td>
      <td>{row.remarks}</td>
      <td>
        {row.sts === "P" && <span>Pending</span>}
        {row.sts === "A" && <span>Active</span>}
        {row.sts === "R" && <span>Rejected</span>}
        {row.sts === "BK" && <span>Blocked</span>}
        {row.sts === "B" && <span>Blacklisted</span>}
        {row.sts === "I" && <span>Inactive</span>}
      </td>
    </tr>
  );

  const renderPendingRow = (row, index) => (
    <tr key={index}>
      <td>{++index}</td>
      <td>{row.peid === localStorage.getItem("id") ? row.tid : row.peid}</td>
      <td>{row.pename === localStorage.getItem("name") ? row.tname : row.pename}</td>
      <td>
        <a href={row.approvalDoc} target="blank" className="ms-5">
          <span className="material-symbols-outlined">note_stack</span>
        </a>
      </td>
      <td>
        {new Date(row.cts * 1000)
          .toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
          .replace(/,/, '')}
      </td>
      <td>
        <span>
          <button className="approve_btn me-4" value={[row.tid, row.peid, row.ref]} onClick={(e) => { approve(e) }}>
            Approve
          </button>
          <button className="revoke_btn" value={[row.tid, row.peid, row.ref]} onClick={(e) => { reject(e) }}>
            Reject
          </button>
        </span>
      </td>
    </tr>
  );

  const renderRouteRow = (row, index) => {
    const rowId = row._id; // Assuming _id is unique for each row
    const rowPrivateKey = row.privateKey;
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row.hashChain}</td>
        {
          localStorage.getItem("id") === row.tid &&
          <td className="copy_col">
            {keyTmId === rowId + true ? (
              <div className={`copy-container ${isActive ? 'active' : ''}`}>
                <span>{"..." + row.privateKey.slice(-33)}</span>
                <span className="material-symbols-outlined copy-icon" onClick={() => copyText(rowPrivateKey)}>
                  content_copy
                </span>
              </div>
            ) : (
              <div className="material-symbols-outlined ms-4" style={{ color: "blue", cursor: "pointer" }} onClick={() => { getPrivateKey(rowId) }}>
                key
              </div>
            )}
          </td>
        }
      </tr>
    );
  };

  let handleTableRow = (row, index) => {
    switch (isToggle) {
      case "requested":
        return renderRequestedRow(row, index);
      case "approved":
        return renderApprovedRow(row, index);
      case "rejected":
        return renderRejectedRow(row, index);
      case "pending":
        return renderPendingRow(row, index);
      case "route":
        return renderRouteRow(row, index);
      default:
        return null;
    }
  };

  const getPrivateKey = (id) => {
    setOpenModal(true)
    setKeyTMId(id)
  }

  const approve = (e) => {
    let myArray = e.target.value.split(',');
    setID(myArray)
    setApproveModal(true)
  }
  const reject = (e) => {
    let myArray = e.target.value.split(',');
    setID(myArray)
    setRejectModal(true)
  }

  const toggle = (value) => {
    setIsToggle(value)
    setTableData([])
    if (value !== isToggle) setSearch("")
  }

  // const searchData = (e) => {
  // }

  return (
    <PrivateLayout>
      {isLoading && <Loaders />}
      <div className="mt-3 Dash_header">
        <div className="buttons">
          <div className="wrapper">
            <div className="searchBar">
              {isToggle !== "route" &&
                <>
                  <input
                    id="searchQueryInput"
                    type="text"
                    name="searchQueryInput"
                    placeholder="Search by Organisation ID/Name"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}
                  />
                  <button
                    id="searchQuerySubmit"
                    type="submit"
                    name="searchQuerySubmit"
                  >
                    <span>
                      <svg
                        style={{ width: "24px", height: "24px" }}
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#8153A2"
                          d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                        />
                      </svg>
                    </span>
                  </button>
                </>
              }
            </div>
          </div>
        </div>
        <span className="cursor ms-auto glow-on-hover" onClick={handleOpenModal}>
          <span className="material-symbols-outlined btn_anim">library_add</span>
          <p>Binding Request</p>
        </span>
      </div>

      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <div className="switch-wrapper">
          <input id="request" type="radio" name="switch" />
          <input id="approved" type="radio" name="switch" />
          <input id="reject" type="radio" name="switch" />
          <input id="action" type="radio" name="switch" />
          <input id="revoke" type="radio" name="switch" defaultChecked />
          <label
            className="one_fifth_w curve_left"
            htmlFor="revoke"
            onClick={() => toggle("route")}
          >
            Routes
          </label>
          <label
            className="one_fifth_w "
            htmlFor="request"
            onClick={() => toggle("requested")}
          >
            Binding Request
          </label>
          <label
            className="one_fifth_w"
            htmlFor="approved"
            onClick={() => toggle("approved")}
          >
            Approved
          </label>
          <label
            className="one_fifth_w"
            htmlFor="reject"
            onClick={() => toggle("rejected")}
          >
            Rejected
          </label>
          <label
            className="one_fifth_w curve_right"
            htmlFor="action"
            onClick={() => toggle("pending")}
          >
            Binding Action
          </label>

          <span className="highlighter"></span>
        </div>

        <div className="table_fixhead">
          {isToggle === "requested" && (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Organisation Id</th>
                  <th>Organisation Name</th>
                  <th>Letter of Authorization</th>
                  <th>Date & Time of Application</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {tableData.length > 0 ? (
                  tableData.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {isToggle === "approved" && (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Organisation Id</th>
                  <th>Organisation Name</th>
                  <th>Letter of Authorization</th>
                  <th>Date & Time of Application</th>
                  <th>Status</th>
                  <th>Binding Hash</th>
                </tr>
              </thead>
              <tbody>
                {tableData.length > 0 ? (
                  tableData.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {(isToggle === "rejected" && filterValue === "R") && (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Organisation Id</th>
                  <th>Organisation Name</th>
                  <th>Letter of Authorization</th>
                  <th>Date & Time of Application</th>
                  <th>Remarks</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.length > 0 ? (
                  tableData?.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          {(isToggle === "rejected" && filterValue === "T") && (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Organisation Id</th>
                  <th>Organisation Name</th>
                  <th>Letter of Authorization</th>
                  <th>Date & Time of Application</th>
                  <th>Remarks</th>
                  <th>Revoked By</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.length > 0 ? (
                  tableData?.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {isToggle === "pending" && (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Organisation Id</th>
                  <th>Organisation Name</th>
                  <th>Letter of Authorization</th>
                  <th>Date & Time of Application</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.length > 0 ? (
                  tableData?.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {isToggle === "route" && (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Chain</th>
                  <th>Private Key</th>
                </tr>
              </thead>
              <tbody>
                {hashData?.length > 0 ? (
                  hashData?.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

        </div>
      </div>

      <ApproveModal
        open={approveModal}
        onClose={() => setApproveModal(false)}
        id={id}
      />
      <RejectModal
        open={rejectModal}
        onClose={() => setRejectModal(false)}
        id={id}
      />

      {/* MODAL FOR PRIVATE KEY */}
      <ModalPrivateKey
        open={openModal}
        keyIdFalse={() => setKeyTMId(keyTmId + true)}
        onClose={() => setOpenModal(false)}
      />
    </PrivateLayout>

  );

};

export default Binding;
