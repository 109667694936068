// authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: false,
    user: null,
};
const adminAuthSlice = createSlice({

    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.isAuthenticated = true;
            state.user = action.payload;
            localStorage.setItem('authState', JSON.stringify(state));
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = {};
            localStorage.removeItem('authState');
        },
    },
});

export const { login, logout } = adminAuthSlice.actions;
export const selectAuth = (state) => state.auth;
export const adminAuthReducer = adminAuthSlice.reducer;