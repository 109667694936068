import { Sidebardata } from "./AdminData";
import React from "react";
import { NavLink, Link } from "react-router-dom";
import * as imgs from "../Assets/images";
export const DashHeader = () => {

  let email =
    localStorage.getItem("email") !== undefined
      ? localStorage.getItem("email")
      : "";

  let dateString = localStorage.getItem("time");
  let time = dateString.slice(16, 21); // Extracts characters from index 16 to 20

  return (
    <div className="Dash_header white">
      <div>
        <img src={imgs.operator_i} alt="logo" />

        <div className="header-info">
          <span>
            {localStorage.getItem("name")}
            <br />
            <p className="m-0">{localStorage.getItem("type")} Name</p>
          </span>
          <span>
            {time}
            <br />
            <p className="m-0">Login Time</p>
          </span>
          <span>
            {email.replace(/(?<=.{5}).(?=[^@]*?@)/g, '*')}
            <br />
            <p className="m-0">Login ID</p>
          </span>
          <span>
            {localStorage.getItem("id")}
            <br />
            <p className="m-0">{localStorage.getItem("type")} ID</p>
          </span>
        </div>
      </div>

      <div className="notif">
        <span className="material-symbols-outlined">notifications</span>
        <span className="material-symbols-outlined">question_mark</span>
        <span
          className="material-symbols-outlined"
          type="button"
          role="button"
          tabIndex="0"
          style={{
            transition: "transform 0.3s ease",
            transform: "scale(1) rotate(0deg)", // Initial scale and rotation
            cursor: "pointer"
          }}
          onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.1) rotate(30deg)")}
          onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1) rotate(0deg)")}
          onFocus={(e) => (e.currentTarget.style.transform = "scale(1.1) rotate(30deg)")}
          onBlur={(e) => (e.currentTarget.style.transform = "scale(1) rotate(0deg)")}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.currentTarget.style.transform = "scale(1.1) rotate(30deg)";
              // Optionally trigger a click or another action
              e.currentTarget.click();
            }
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault(); // Prevent the default action to avoid scrolling when space is pressed
              e.currentTarget.style.transform = "scale(1) rotate(0deg)";
            }
          }}
        >
          settings
        </span>

      </div>
    </div>
  );
};

// Sidebar
export const Sidebar = () => {
  let sideBarList = (item, index) => {
    return (
      <NavLink
        to={item.link}
        activeclassname="active"
        className="menuItems cursor"
        key={index}
        role="menuitem"
        tabIndex={0}
      >
        <div className="menu_icon">{item.icon}</div>
        <div className="menu_heading">{item.heading}</div>
      </NavLink>
    );
  };

  return (
    <div className="Sidebar">
      <div className="dash_options">
        <img className="mb-4" src={imgs.mainLogo} alt="logo" />
        {Sidebardata.map((item, index) => sideBarList(item, index))}
      </div>
      <div className="bottom">
        <Link
          to={"/admin-login"}
          className="text-decoration-none d-flex align-items-center"
          onClick={() => localStorage.clear()}
          type="button"
          tabIndex={0}
        >
          <span className="material-symbols-outlined">move_item</span>
          <span className="ms-2">Log Out</span>
        </Link>
      </div>
    </div>
  );
};
