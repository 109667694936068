import React, { useState, useEffect } from "react";
import { PrivateLayout } from "../../layouts/PrivateLayout";
import { useTmlistRegMutation } from "../../Services/services";
import Loaders from "../../Assets/loaders/loaders";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
// import CustomModal from "../Components/modal_reg";

const Registrations = () => {
  const [isLoading, setIsLoading] = useState(false);
  let [urls, setUrls] = useState([]);
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  let [getAllDomainRender] = useTmlistRegMutation();
  useEffect(() => {
    if (localStorage.getItem("type") !== "Telemarketer") {
      navigate("/")
    } else
    handleDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleDomains = () => {
    setIsLoading(true);
    try {
      getAllDomainRender()
        .then((success) => {
          if (success.data.statusCode === 200) {
            setUrls(success.data.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleMouse = (e, type, rowId) => {
    if (e.type === "mouseenter") {
      setIsHovered(`${type}-${rowId}`);
    } else {
      setIsHovered("");
    }
  };

  let handleTableRow = (row, index) => {
    const rowId = row._id; // Assuming _id is unique for each row
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row._id}</td>
        <td>{row.pename}</td>
        <td
          onMouseEnter={(e) => handleMouse(e, "white", rowId)}
          onMouseLeave={(e) => handleMouse(e, "white", rowId)}
          id={`whitelisted-${rowId}`}
        >
          {isHovered === `white-${rowId}` ? ( 
            <Link to={`/registrations/${"whitelisted"}/${rowId}`}>
              <button className="view_btn">View more...</button>
            </Link>
          ) : (
            row.whitelistCount
          )}
        </td>
        <td
          onMouseEnter={(e) => handleMouse(e, "black", rowId)}
          onMouseLeave={(e) => handleMouse(e, "black", rowId)}
          id={`blacklisted-${rowId}`}
        >
          {isHovered === `black-${rowId}` ? (
            <Link to={`/registrations/${"blacklisted"}/${rowId}`}>
              <button className="view_btn">View more...</button>
            </Link>
          ) : (
            row.blacklistCount
          )}
        </td>
        <td>{row.totalCount}</td>
      </tr>
    );
  };

  return (
    <PrivateLayout>
      {isLoading && <Loaders />}

      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <span className="top_border_vlt">
          <p>Registered URLs</p>
        </span>
        <div className="table_fixhead max">
          <table>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Entity ID</th>
                <th>Entity Name</th>
                <th>Whitelisted URL</th>
                <th>Blacklisted URL</th>
                <th>Total URL</th>
              </tr>
            </thead>
            <tbody>
              {urls.length > 0 ? (
                urls.map((row, index) => handleTableRow(row, index))
              ) : (
                <tr>
                  <td
                    colSpan="100%"
                    style={{
                      textAlign: "center",
                      paddingTop: "200px",
                      fontWeight: "bold",
                    }}
                  >
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </PrivateLayout>
  );
};

export default Registrations;
