import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

/* Common Routing */
import SelectorLogin from './pages/Common/selectorsLogin';
import Login from "./pages/Common/Login";
import Error from './pages/Common/errorpage';

import Dashboard from "./pages/Telemarketer/Dashboard";
import Registrations from './pages/Telemarketer/Registrations';
import URLInside from './pages/Telemarketer/URLInside';
import ManageIp from './pages/Telemarketer/Manage';
import Complaints from './pages/Telemarketer/Complaints';
import Binding from './pages/Telemarketer/Binding';
import Callback from './pages/Telemarketer/Callback';
import CallbackInside from './pages/Telemarketer/CallbackInside';
import BindingPartner from './pages/Telemarketer/Binding/BindingPartner';


/* Entity Routing */
import EntityDashboard from './pages/Entity/EntityDashboard';
import EntityRegistration from './pages/Entity/EntityRegistrations';
import EntityScrubbing from './pages/Entity/EntityMapping';
import EntityManageIP from './pages/Entity/EntityManage';
import EntityManageInside from './pages/Entity/EntityManageInside';
import EntityComplaints from './pages/Entity/EntityComplaints';
import EntityBindingPartner from './pages/Entity/Binding/EntityBindingPartner';
import EntityCallback from './pages/Entity/EntityCallback';


/* Admin Routing */
import AdminLogin from './pages/Admin/AdminLogin';
import AdminDashboard from './pages/Admin/AdminDashboard';
import AdminURLDetail from './pages/Admin/AdminURLDetail';
import AdminIPDetail from './pages/Admin/AdminIPDetail';
import AdminEntityDetail from './pages/Admin/AdminEntityDetail';
import AdminTelemarketerDetail from './pages/Admin/AdminTelemarketerDetail';
import AdminSMSReport from './pages/Admin/AdminSMSReport';
import AdminURLInside from './pages/Admin/AdminURLInside';
import AdminIPInside from './pages/Admin/AdminIPInside';
import AdminSendSMS from './pages/Admin/AdminSendSMS';
import AdminCallbackDetail from './pages/Admin/AdminCallbackDetail';
import AdminCallbackInside from './pages/Admin/AdminCallbackInside';
import AdminEntitydetailInside from './pages/Admin/AdminEntitydetailInside';
import AuthGuard from './Components/AuthGuard';
import AdminAuthGuard from './Components/AdminAuthGuard';
import EntitySmsReport from './pages/Entity/EntitySMSReport';
import AdminRouteDetail from './pages/Admin/AdminRouteDetail';
import AdminRouteInside from './pages/Admin/AdminRouteInside';
import EntityBindingInside from './pages/Entity/EntityBindingInside';
import EntityBinding from './pages/Entity/EntityBinding';


const Routers = () => {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>

        <Route path="/" element={<SelectorLogin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<AuthGuard><Dashboard /></AuthGuard>} />
        <Route path="/registrations" element={<AuthGuard><Registrations /></AuthGuard>} />
        <Route path="/registrations/:value/:id" element={<AuthGuard><URLInside /></AuthGuard>} />
        <Route path="/manageIp" element={<AuthGuard><ManageIp /></AuthGuard>} />
        <Route path="/complaints" element={<AuthGuard><Complaints /></AuthGuard>} />
        <Route path="/manage-route" element={<AuthGuard><Binding /></AuthGuard>} />
        <Route path="/manage-route/bindingpartner" element={<AuthGuard><BindingPartner /></AuthGuard>} />
        <Route path="/callback" element={<AuthGuard><Callback /></AuthGuard>} />
        <Route path="/callback/:id" element={<AuthGuard><CallbackInside /></AuthGuard>} />



        <Route path="/entitydashboard" element={<AuthGuard><EntityDashboard /></AuthGuard>} />
        <Route path="/entityregistrations" element={<AuthGuard><EntityRegistration /></AuthGuard>} />
        <Route path="/entitymapping" element={<AuthGuard><EntityScrubbing /></AuthGuard>} />
        <Route path="/entitymanageIp" element={<AuthGuard><EntityManageIP /></AuthGuard>} />
        <Route path="/entitymanageIp/:value/:id" element={<AuthGuard><EntityManageInside /></AuthGuard>} />
        <Route path="/entitycomplaints" element={<AuthGuard><EntityComplaints /></AuthGuard>} />
        <Route path="/entitymanage-route" element={<AuthGuard><EntityBinding /></AuthGuard>} />
        <Route path="/entitymanage-route/view-details" element={<AuthGuard><EntityBindingInside /></AuthGuard>} />

        <Route path="/entitymanage-route/entitybindingpartner" element={<AuthGuard><EntityBindingPartner /></AuthGuard>} />
        <Route path="/entitycallback" element={<AuthGuard><EntityCallback /></AuthGuard>} />
        <Route path="/entitysmsreport" element={<AuthGuard><EntitySmsReport /></AuthGuard>} />


        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/admin-dashboard" element={<AdminAuthGuard><AdminDashboard /></AdminAuthGuard>} />
        <Route path="/admin-url" element={<AdminAuthGuard><AdminURLDetail /></AdminAuthGuard>} />
        <Route path="/admin-url/:value/:id" element={<AdminAuthGuard><AdminURLInside /></AdminAuthGuard>} />
        <Route path="/admin-ip" element={<AdminAuthGuard><AdminIPDetail /></AdminAuthGuard>} />
        <Route path="/admin-ip/:value/:id" element={<AdminAuthGuard><AdminIPInside /></AdminAuthGuard>} />
        <Route path="/admin-pe-details" element={<AdminAuthGuard><AdminEntityDetail /></AdminAuthGuard>} />
        <Route path="/admin-pe-details/:id" element={<AdminAuthGuard><AdminEntitydetailInside /></AdminAuthGuard>} />
        <Route path="/admin-tm-details" element={<AdminAuthGuard><AdminTelemarketerDetail /></AdminAuthGuard>} />
        <Route path="/admin-send-sms" element={<AdminAuthGuard><AdminSendSMS /></AdminAuthGuard>} />
        <Route path="/admin-sms-reports" element={<AdminAuthGuard><AdminSMSReport /></AdminAuthGuard>} />
        <Route path="/admin-callback" element={<AdminAuthGuard><AdminCallbackDetail /></AdminAuthGuard>} />
        <Route path="/admin-callback/:id" element={<AdminAuthGuard><AdminCallbackInside /></AdminAuthGuard>} />
        <Route path="/admin-route-details" element={<AdminAuthGuard><AdminRouteDetail /></AdminAuthGuard>} />
        <Route path="/admin-route-details/routes-inside" element={<AdminAuthGuard><AdminRouteInside /></AdminAuthGuard>} />


        {/* ERROR PAGE */}
        <Route
          path="*"
          element={
            <Error />
          }
        />

      </Routes>
    </BrowserRouter>
  )
}


export default Routers;