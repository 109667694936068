import React, { useState, useEffect } from "react";
import {
  useListIPMutation,
} from "../../Services/services";
import { EntityPrivateLayout } from "../../layouts/EntityPrivateLayout";
import Loaders from "../../Assets/loaders/loaders";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const EntityManageIP = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isToggle/* , setIsToggle */] = useState("whitelisted");
  const [isHovered, setIsHovered] = useState(false);
  const [search, setSearch] = useState("")
  const navigate = useNavigate();
  let [urls, setUrls] = useState([]);

  let [getRegList] = useListIPMutation();


  useEffect(() => {
    if (localStorage.getItem("type") !== "Enterprise") {
      navigate("/")
    } else 
    handleDomains();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToggle, search]);

  let handleDomains = () => {
    const postData = {
      tmid: "",
      ipType: isToggle,
      search2: "",
      search1: search
    };
    setIsLoading(true);
    try {
      getRegList(postData).then((success) => {
        if (success.data.statusCode === 200) {
          setUrls(success.data.data);
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleMouse = (e, type, rowId) => {
    if (e.type === "mouseenter") {
      setIsHovered(`${type}-${rowId}`);
    } else {
      setIsHovered("");
    }
  };

  let handleTableRow = (row, index) => {
    const rowId = row._id; // Assuming _id is unique for each row
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row._id}</td>
        <td>{row.tname}</td>
        <td
          onMouseEnter={(e) => handleMouse(e, "white", rowId)}
          onMouseLeave={(e) => handleMouse(e, "white", rowId)}
          id={`whitelisted-${rowId}`}
        >
          {isHovered === `white-${rowId}` ? (
            <Link to={`/entitymanageIp/${"whitelisted"}/${rowId}`}>
              <button className="view_btn">View more...</button>
            </Link>
          ) : (
            row.whitelistCount
          )}
        </td>
        <td
          onMouseEnter={(e) => handleMouse(e, "black", rowId)}
          onMouseLeave={(e) => handleMouse(e, "black", rowId)}
          id={`blacklisted-${rowId}`}
        >
          {isHovered === `black-${rowId}` ? (
            <Link to={`/entitymanageIp/${"blacklisted"}/${rowId}`}>
              <button className="view_btn">View more...</button>
            </Link>
          ) : (
            row.blacklistCount
          )}
        </td>
        <td>{row.totalCount}</td>
      </tr>
    );
  };

  return (
    <EntityPrivateLayout>
      {isLoading && <Loaders />}
      <div className="mt-3 Dash_header">
        <div className="buttons">
          <div className="wrapper">
            <div className="searchBar">
              <input
                id="searchQueryInput"
                type="text"
                name="searchQueryInput"
                placeholder="Search by Telemarketer ID/Name"
                defaultValue={search}
                onChange={(e) => { setSearch(e.target.value) }}
              />
              <button
                id="searchQuerySubmit"
                type="submit"
                name="searchQuerySubmit"
              >
                <span>
                  <svg
                    style={{ width: "24px", height: "24px" }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#8153A2"
                      d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>

          {/* <div className="wrapper filter">
            <div className="label" htmlFor="filter">
              Filters
            </div>

            <select name="filter" id="filter" className="filter_options">
              <option value="all">All</option>
              <option value="white">White</option>
              <option value="black">Black</option>
            </select>
          </div> */}

          {/* <div className="wrapper records">
            <div className="label" htmlFor="filter">
              Show Records
            </div>

            <select name="filter" id="filter" className="filter_options">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="10">10</option>
            </select>
          </div> */}
        </div>
        {/* <span className="add_btn cursor ms-auto" onClick={handleOpenModal}>
          <span className="material-symbols-outlined">add_circle</span>
          <p>IP Registration</p>
        </span> */}
      </div>

      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <span className="top_border_vlt">
          <p>Registered IPs</p>
        </span>
        <div className="table_fixhead">
          <table>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Telemarketer ID</th>
                <th>Telemarketer Name</th>
                <th>Whitelisted IP</th>
                <th>Blacklisted IP</th>
                <th>Total IP</th>
              </tr>
            </thead>
            <tbody>
              {urls.length > 0 ? (
                urls.map((row, index) => handleTableRow(row, index))
              ) : (
                <tr>
                  <td
                    colSpan="100%"
                    style={{
                      textAlign: "center",
                      paddingTop: "200px",
                      fontWeight: "bold"
                    }}
                  >
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

    </EntityPrivateLayout>
  );
};

export default EntityManageIP;
