import React, { useState, useEffect } from "react";
import { useListIPMutation } from "../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../../Assets/loaders/loaders";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { EntityPrivateLayout } from "../../layouts/EntityPrivateLayout";


const EntityManageInside = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { value } = useParams();
  const { id } = useParams();

  const [urlList] = useListIPMutation();
  const navigate = useNavigate();

  let [urls, setUrls] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("type") !== "Enterprise") {
      navigate("/")
    } else
      handleDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleDomains = () => {
    let postData = {
      tmid: id,
      ipType: value,
      search2: "",
      search1: ""
    };
    setIsLoading(true);
    urlList(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          setUrls(success.data.data);
        } else if (success.error.data.statusCode > 300) {
          setIsLoading(false);
        } else if (success.data.statusCode > 300) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  let handleTableRow = (row, index) => {
    if (value === "whitelisted") {
      return (
        <tr key={index}>
          <td>{++index}</td>
          <td>{row.ip}</td>
          <td>{new Date(row.createdAt).toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} {new Date(row.createdAt).getFullYear()}, {new Date(row.createdAt).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
          <td>{new Date(row.updatedAt).toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} {new Date(row.updatedAt).getFullYear()}, {new Date(row.updatedAt).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
          {/* <td>
            {row.status === "ACTIVE" ? <span style={{ color: "#30ad23" }}>ACTIVE</span> : <span style={{ color: "#ed1c36" }}>BLOCK</span>}
          </td> */}
        </tr>
      );
    } else {
      return (
        <tr key={index}>
          <td>{++index}</td>
          <td>{row.ip}</td>
          <td>{new Date(row.createdAt).toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} {new Date(row.createdAt).getFullYear()}, {new Date(row.createdAt).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
          <td>{new Date(row.updatedAt).toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} {new Date(row.updatedAt).getFullYear()}, {new Date(row.updatedAt).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
        </tr>
      );
    }
  };

  const goBack = () => {
    navigate("/entitymanageIp");
  };
  return (
    <EntityPrivateLayout>
      {isLoading && <Loaders />}

      {/* ------------------------------------data table----------------------------------- */}
      {value === "whitelisted" ? (
        <div className="data_layout_4">
          <div className="top_border_vlt d-flex justify-content-center">
            <h5 className="ms-auto m-0">Whitelisted IPs</h5>
            <span
              className="material-symbols-outlined cursor ms-auto"
              onClick={() => goBack()}
            >
              arrow_back
            </span>
          </div>
          <div className="table_fixhead">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>IPs</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  {/* <th>Status</th> */}
                </tr>
              </thead>
              <tbody>
                {urls.length > 0 ? (
                  urls.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td
                      colSpan="100%"
                      style={{
                        textAlign: "center",
                        paddingTop: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="data_layout_4">
          <div className="top_border_vlt d-flex justify-content-center">
            <h5 className="ms-auto m-0">Blacklisted IPs</h5>
            <span
              className="material-symbols-outlined cursor ms-auto"
              onClick={() => goBack()}
            >
              arrow_back
            </span>
          </div>
          <div className="table_fixhead">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>IPs</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                </tr>
              </thead>
              <tbody>
                {urls.length > 0 ? (
                  urls.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td
                      colSpan="100%"
                      style={{
                        textAlign: "center",
                        paddingTop: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </EntityPrivateLayout>
  );
};

export default EntityManageInside;
