export const Sidebardata = [
  {
    id: 1,
    icon: <span className="material-symbols-outlined">dashboard</span>,
    heading: "Dashboard",
    link: "/dashboard",
  },
  {
    id: 2,
    icon: <span className="material-symbols-outlined">tenancy</span>,
    heading: "Manage Route",
    link: "/manage-route",
  },
  // {
  //   id: 3,
  //   icon: <span className="material-symbols-outlined">link</span>,
  //   heading: "Registered URLs",
  //   link: "/registrations",
  // },
  {
    id: 4,
    icon: <span className="material-symbols-outlined">edit_location_alt</span>,
    heading: "Manage IP",
    link: "/manageIp",
  },
  // {
  //   id: 5,
  //   icon: <span className="material-symbols-outlined">phone_callback</span>,
  //   heading: "Callback Numbers",
  //   link: "/callback",
  // },
 
  {
    id: 6,
    icon: <span className="material-symbols-outlined">feedback</span>,
    heading: "UCC Complaints",
    link: "/complaints",
  }
];
