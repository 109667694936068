import React from "react";
import "./loader.css";
import { SunspotLoader } from "react-awesome-loaders";

export default function Loaders() {
  return (
    <div id="overlayLoader1">
      <SunspotLoader
        gradientColors={["#7B4C91", "#E0E7FF"]}
        shadowColor={"#3730A3"}
        desktopSize={"128px"}
        mobileSize={"30px"}
      />
    </div>
  );
}
