// authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const getStoredAuthState = () => {
    const storedState = localStorage.getItem('authState');
    return storedState ? JSON.parse(storedState) : null;
};

const initialState = getStoredAuthState() || {
    isAuthenticated: false,
    user: null,
};

const authSlice = createSlice({

    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.isAuthenticated = true;
            state.user = action.payload;
            localStorage.setItem('authState', JSON.stringify(state));
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = {};
            localStorage.removeItem('authState');
        },
    },
});

export const { login, logout } = authSlice.actions;
export const selectAuth = (state) => state.auth;
export const authReducer = authSlice.reducer;