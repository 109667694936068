import React, { useState, useEffect } from "react";
import { useAdminChainDetailsMutation } from "../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../../Assets/loaders/loaders";
import { useLocation, useNavigate } from "react-router-dom";
import { EntityPrivateLayout } from "../../layouts/EntityPrivateLayout";


export default function EntityBindingInside(){

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [detailList] = useAdminChainDetailsMutation();

    let [pe, setPe] = useState([]);
    let [tm, setTm] = useState([]);

    const location = useLocation();
    const state = location.state;

    useEffect(() => {
        if (localStorage.getItem("type") !== "Enterprise") {
            navigate("/")
        } else {
            handleDomains();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let handleDomains = () => {
        let postData = {
            chain: state,
        };
        setIsLoading(true);
        detailList(postData)
            .then((success) => {
                if (success?.data?.statusCode === 200) {
                    setIsLoading(false);
                    setPe(success.data.data.entity_details[0].name)
                    const newTm = []
                    success.data.data.tm_details.forEach(row => {
                        newTm.push(row.name);
                    });
                    setTm(newTm);
                } else if (success.error.data.statusCode > 300) {
                    setIsLoading(false);
                } else if (success.data.statusCode > 300) {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error.message);
            });
    };

    const goBack = () => {
        navigate("/entitymanage-route");
    };
    return (
        <EntityPrivateLayout>
            {isLoading && <Loaders />}

            {/* ------------------------------------data table----------------------------------- */}
            <div className="data_layout_4 mt-auto">
                <div className="top_border_vlt d-flex justify-content-center">
                    <h5 className="ms-auto m-0">Linked Partners</h5>
                    <span
                        className="material-symbols-outlined cursor ms-auto"
                        role="button"
                        tabIndex="0"
                        onClick={() => goBack()}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault();  // Prevent default spacebar scrolling
                                goBack();
                            }
                        }}
                    >
                        arrow_back
                    </span>
                </div>
                <div className="table_fixhead max">
                    <table>
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                <th>Entity</th>
                                <th>Telemarketer(s)</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>1</td>
                                <td>{pe}</td>
                                <td>{tm.join(" => ")}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

        </EntityPrivateLayout>
    );
};
