import React, { useState, useEffect } from "react";
import { useAdminMappingURLMutation } from "../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../../Assets/loaders/loaders";
import { AdminPrivateLayout } from "../../layouts/AdminPrivateLayout";
import { useNavigate, useParams } from "react-router-dom";

const AdminEntitydetailInside = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const [mappingList] = useAdminMappingURLMutation();
  const navigate = useNavigate();

  let [urls, setUrls] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("type") !== "ADMIN") {
      navigate("/admin-login")
    } else
      handleDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleDomains = () => {
    let postData = {
      "peid": id,
      "templateId": ""
    };
    setIsLoading(true);
    mappingList(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          setUrls(success.data.data.urlMappingResult);
        } else if (success.error.data.statusCode > 300) {
          setIsLoading(false);
        } else if (success.data.statusCode > 300) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  let handleTableRow = (row, index) => {
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row.templateId}</td>
        <td>{row.url}</td>
        <td>{new Date(row.updatedAt).toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} {new Date(row.updatedAt).getFullYear()}, {new Date(row.updatedAt).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
        <td>
          <label htmlFor="switch" className="switch">
            <input type="checkbox" id="switch" disabled checked />
            <span className="slider round" style={{ cursor: "not-allowed" }}></span>
          </label>
        </td>

      </tr>
    );
  };


  const goBack = () => {
    navigate("/admin-pe-details");
  };
  return (
    <AdminPrivateLayout>
      {isLoading && <Loaders />}

      {/* ------------------------------------data table----------------------------------- */}

      <div className="data_layout_4 mt-auto">
        <div className="top_border_vlt d-flex justify-content-center">
          <h5 className="ms-auto m-0">Entity Mapped URL Details</h5>
          <span
            className="material-symbols-outlined cursor ms-auto"
            role="button"
            tabIndex="0"
            onClick={() => goBack()}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();  // Prevent default spacebar scrolling
                goBack();
              }
            }}
          >
            arrow_back
          </span>
        </div>
        <div className="table_fixhead max">
          <table>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Content ID</th>
                <th>URL</th>
                <th>Updated At</th>
                <th>Disable/Enable</th>
              </tr>
            </thead>
            <tbody>
              {urls.length > 0 ? (
                urls.map((row, index) => handleTableRow(row, index))
              ) : (
                <tr>
                  <td
                    colSpan="100%"
                    style={{
                      textAlign: "center",
                      paddingTop: "200px",
                      fontWeight: "bold",
                    }}
                  >
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </AdminPrivateLayout>
  );
};

export default AdminEntitydetailInside;
