import React, { useState, useEffect } from "react";
import { PrivateLayout } from "../../layouts/PrivateLayout";
import * as imgs from "../../Assets/images";
import {
  useTmDashboardMutation,
  // useTmlistRegMutation,
} from "../../Services/services";
// import GraphChart from "../Utils/charts";
// import PieChartPurple from "../Utils/piechart";
import Loaders from "../../Assets/loaders/loaders";
import { toast } from "react-toastify";
import Counter from "../../Components/counter";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("type") !== "Telemarketer") {
      navigate("/");
    } else summaryRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // dashboard summary API
  let [summaryRender] = useTmDashboardMutation();
  let [dashboardSummary, setDashboardSummary] = useState({
    boundEnt: 0,
    activeEnt: 0,
    blockEnt: 0,
    routes: 0,
    active: 0,
    nonactive: 0,
    linkedTm: 0,  
    activeTm: 0,
    blockedTm: 0,
    totalurl: 0,
    whiteurl: 0,
    blackurl: 0,
    totalIp: 0,
    activeIp: 0,
    blockIp: 0,
    whiteIp: 0,
    blackIp: 0,
    numbers: 0,
    activeNum: 0,
    blockNum: 0,
    callback: 0
  });
  useEffect(() => {
    setIsLoading(true);
    summaryRender()
      .then((success) => {
        if (success?.data.statusCode === 200) {
          setDashboardSummary({
            ...dashboardSummary,
            boundEnt: success.data.data.tmDashboardData.totalPeCount[0].value,
            activeEnt: success.data.data.tmDashboardData.statusPeCounts[0].count,
            blockEnt: success.data.data.tmDashboardData.statusPeCounts[1].count,
            routes: success.data.data.totalHashCount,
            active: success.data.data.ApprovedHashCount,
            nonactive: success.data.data.RejectedHashCount,
            linkedTm:  success.data.data.tmDashboardData.totalTmCount[0].value,
            activeTm:  success.data.data.tmDashboardData.statusTmCounts[0].count,
            blockedTm:  success.data.data.tmDashboardData.statusTmCounts[1].count,
            totalurl: success.data.data.tmDashboardData.totalURLCount[0].value,
            whiteurl: success.data.data.tmDashboardData.statusURLCounts[0].count,
            blackurl: success.data.data.tmDashboardData.statusURLCounts[1].count,
            totalIp: success.data.data.tmDashboardData.totalIPCount[0].value,
            activeIp: success.data.data.tmDashboardData.statusIPCounts[0].count,
            blockIp: success.data.data.tmDashboardData.statusIPCounts[1].count,
            whiteIp: success.data.data.tmDashboardData.ipTypeCounts[1].count,
            blackIp: success.data.data.tmDashboardData.ipTypeCounts[0].count,
            numbers: success.data.data.tmDashboardData.totalCallbackCount[0].value,
            activeNum: success.data.data.tmDashboardData.statusCallbackCounts[0].count,
            blockNum: success.data.data.tmDashboardData.statusCallbackCounts[1].count,
            callback: success.data.data.tmDashboardData.totalUccCount[0].value
          });
          setIsLoading(false);
        } else if (success?.data.statusCode > 200) {
          setIsLoading(false);
          toast.error(success?.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PrivateLayout>
      {isLoading && <Loaders />}

      <div className="data_layout_2">

        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Linked Enterprises</p>
              <h1 className="headCount">
                <Counter number={dashboardSummary.boundEnt} />
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Active"
            >
              <span className="material-symbols-outlined">check_circle</span>
              <p>
                <Counter number={dashboardSummary.activeEnt} />
              </p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Inactive"
            >
              <span className="material-symbols-rounded">cancel</span>
              <p>
                <Counter number={dashboardSummary.blockEnt} />
              </p>
            </span>
          </div>
        </div>

        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Linked Telemarketers</p>
              <h1 className="headCount">
                <Counter number={dashboardSummary.linkedTm} />
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Active"
            >
              <span className="material-symbols-outlined">check_circle</span>
              <p>
                <Counter number={dashboardSummary.activeTm} />
              </p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Inactive"
            >
              <span className="material-symbols-rounded">cancel</span>
              <p>
                <Counter number={dashboardSummary.blockedTm} />
              </p>
            </span>
          </div>
        </div>
        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Routes</p>
              <h1 className="headCount">
                <Counter number={dashboardSummary.routes} />
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Approved Routes"
            >
              <span className="material-symbols-outlined">check_circle</span>
              <p>
                <Counter number={dashboardSummary.active} />
              </p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Rejected Routes"
            >
              <span className="material-symbols-rounded">cancel</span>
              <p>
                <Counter number={dashboardSummary.nonactive} />
              </p>
            </span>
          </div>
        </div>
      </div>

      <div className="data_layout_2">
        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">URLs</p>
              <h1 className="headCount">
                <Counter number={dashboardSummary.totalurl} />
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Whitelisted URLs"
            >
              <span className="material-symbols-rounded">add_circle</span>
              <p>
                <Counter number={dashboardSummary.whiteurl} />
              </p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Blacklisted URLs"
            >
              <span className="material-symbols-rounded">block</span>
              <p>
                <Counter number={dashboardSummary.blackurl} />
              </p>
            </span>
          </div>
        </div>

        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Total IP</p>
              <h1 className="headCount">
                <Counter number={dashboardSummary.totalIp} />
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          <div className="d-flex justify-content-end">
            {/* <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Active IPs"
            >
              <span className="material-symbols-outlined">check_circle</span>
              <p>
                <Counter number={dashboardSummary.activeIp} />
              </p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Inactive IPs"
            >
              <span className="material-symbols-rounded">cancel</span>
              <p>
                <Counter number={dashboardSummary.blockIp} />
              </p>
            </span> */}
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Whitelisted IPs"
            >
              <span className="material-symbols-rounded">add_circle</span>
              <p>
                <Counter number={dashboardSummary.whiteIp} />
              </p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Blacklisted IPs"
            >
              <span className="material-symbols-rounded">block</span>
              <p>
                <Counter number={dashboardSummary.blackIp} />
              </p>
            </span>
          </div>
        </div>

        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Callback Numbers</p>
              <h1 className="headCount">
                <Counter number={dashboardSummary.numbers} />
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          {/* <div className="d-flex justify-content-end">
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Active Numbers"
            >
              <span className="material-symbols-rounded">check_circle</span>
              <p>
                <Counter number={dashboardSummary.activeNum} />
              </p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Inactive Numbers"
            >
              <span className="material-symbols-rounded">cancel</span>
              <p>
                <Counter number={dashboardSummary.blockNum} />
              </p>
            </span>
          </div> */}
        </div>
      </div>

      <div className="data_layout_2">
        <div className="data_box w-33">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">UCC Complaints</p>
              <h1 className="headCount">
                <Counter number={dashboardSummary.callback} />
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
        </div>
      </div>
    </PrivateLayout>
  );
};

export default Dashboard;
