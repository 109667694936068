import React, { useState, useEffect } from "react";
import {
  /* useListIPMutation,
  useEditIPMutation,
  useDeleteIPMutation,
  useEnableIPMutation, */
} from "../../Services/services";
import { toast } from "react-toastify";
import {
  useAdminTMListMutation,
} from "../../Services/services";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../../Assets/loaders/loaders";
import { AdminPrivateLayout } from "../../layouts/AdminPrivateLayout";
import { useNavigate } from "react-router-dom";

const AdminTelemarketerDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isToggle] = useState("whitelisted");
  let [getList] = useAdminTMListMutation();
  const navigate = useNavigate();

  const [openModal/* , setOpenModal */] = useState(false);
  let [urls, setUrls] = useState([]);

  // let [getRegList] = useListIPMutation();
  // let [enable] = useEnableIPMutation();
  // let [del] = useDeleteIPMutation();
  // const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("type") !== "ADMIN") {
      navigate("/admin-login")
    } else
      handleDomains();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToggle, openModal]);

  let handleDomains = () => {

    setIsLoading(true);
    getList()
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          setUrls(success.data.data.telemarketer);
        } else if (success.error.data.statusCode > 300) {
          setIsLoading(false);
        } else if (success.data.statusCode > 300) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  // const handleMouse = (e) => {
  //   if (e.target.id === "whitelisted") {
  //     if (e.type === "mouseenter") {
  //       setIsHovered("white");
  //     } else {
  //       setIsHovered("");
  //     }
  //   } else if (e.target.id === "blacklisted") {
  //     if (e.type === "mouseenter") {
  //       setIsHovered("black");
  //     } else {
  //       setIsHovered("");
  //     }
  //   }
  // };

  let handleTableRow = (row, index) => {
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row.id}</td>
        <td>{row.name}</td>
        <td>{new Date(row.cts * 1000).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false }).replace(/,/, '')}</td>
        <td>
          {row.sts === "P" && <span>Pending</span>}
          {row.sts === "A" && <span style={{ color: "#30ad23" }}>Active</span>}
          {row.sts === "R" && <span >Rejected</span>}
          {row.sts === "BK" && <span >Blocked</span>}
          {row.sts === "B" && <span >Blacklisted</span>}
          {row.sts === "I" && <span >Inactive</span>}
        </td>
      </tr>
    );
  };
  return (
    <AdminPrivateLayout>
      {isLoading && <Loaders />}

      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <span className="top_border_vlt">
          <p>Telemarketer Details List</p>
        </span>
        <div className="table_fixhead max">
          <table>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Telemarketer ID</th>
                <th>Telemarketer Name</th>
                <th>Telemarketer Registration Date</th>
                <th>Telemarketer Status</th>
              </tr>
            </thead>
            <tbody>
              {urls.length > 0 ? (
                urls.map((row, index) => handleTableRow(row, index))
              ) : (
                <tr>
                  <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </AdminPrivateLayout>
  );
};

export default AdminTelemarketerDetail;
