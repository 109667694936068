import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EntityPrivateLayout } from "../../layouts/EntityPrivateLayout";
import { useNavigate } from "react-router-dom";
import Loaders from "../../Assets/loaders/loaders";
import { useComplaintsMutation } from "../../Services/services";

const EntityComplaints = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  // const [isHovered, setIsHovered] = useState(false);
  // const [search, setSearch] = useState("");
  let [data, setData] = useState([]);

  let [getComplaints] = useComplaintsMutation();

  useEffect(() => {
    if (localStorage.getItem("type") !== "Enterprise") {
      navigate("/");
    } else handleDomains();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleDomains = () => {
    const postData = {
      sts: "",
      accSort: -1,
      limit: 20,
      pageNo: 1,
      sortOn: "cts",
      peid: localStorage.getItem("id"),
    };
    setIsLoading(true);
    try {
      getComplaints(postData).then((success) => {
        setIsLoading(false);
        if (success.data.statusCode === 200) {
          setData(success.data.data.data);
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
     toast.error(error.message);
    }
  };

  let handleTableRow = (row, index) => {
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row.msisdn}</td>
        <td>{row.cli}</td>
        <td>{new Date(row.cts * 1000).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false }).replace(/,/, '')}</td>
        <td>{row.sts}</td>
        <td style={{maxWidth:"300px", overflow: "hidden", textOverflow: "ellipsis"}}>{row.desc}</td>
        <td>{row.uby}</td>
        <td>{row.tap}</td>
      </tr>
    );
  };

  return (
    <EntityPrivateLayout>
      {isLoading && <Loaders />}
      {/* <div className="mt-3 Dash_header">
        <div className="buttons">
          <div className="wrapper">
            <div className="searchBar">
              <input
                id="searchQueryInput"
                type="text"
                name="searchQueryInput"
                placeholder="Search by Telemarketer ID/Name"
                defaultValue={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <button
                id="searchQuerySubmit"
                type="submit"
                name="searchQuerySubmit"
              >
                <span>
                  <svg
                    style={{ width: "24px", height: "24px" }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#8153A2"
                      d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>

          <div className="wrapper filter">
            <div className="label" htmlFor="filter">
              Filters
            </div>

            <select name="filter" id="filter" className="filter_options">
              <option value="all">All</option>
              <option value="white">White</option>
              <option value="black">Black</option>
            </select>
          </div>

          <div className="wrapper records">
            <div className="label" htmlFor="filter">
              Show Records
            </div>

            <select name="filter" id="filter" className="filter_options">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>
        <span className="add_btn cursor ms-auto" onClick={handleOpenModal}>
          <span className="material-symbols-outlined">add_circle</span>
          <p>IP Registration</p>
        </span>
      </div> */}

      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <span className="top_border_vlt">
          <p>UCC Complaints</p>
        </span>
        <div className="table_fixhead max">
          <table>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Phone No.</th>
                <th>Header ID</th>
                <th>Date</th>
                <th>Status</th>
                <th>Message</th>
                <th>OAP</th>
                <th>TAP</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((row, index) => handleTableRow(row, index))
              ) : (
                <tr>
                  <td
                    colSpan="100%"
                    style={{
                      textAlign: "center",
                      paddingTop: "200px",
                      fontWeight: "bold",
                    }}
                  >
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </EntityPrivateLayout>
  );
};

export default EntityComplaints;
