import React, { useState, useEffect } from "react";
import {
  // useAdminPESMSMutation,
  useAdminSMScountsMutation,
} from "../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EntityPrivateLayout } from "../../layouts/EntityPrivateLayout";
import Loaders from "../../Assets/loaders/loaders";
import { useNavigate } from "react-router-dom";
// import ExampleCDR from "../../ExampleCDR/cdr_2024-02-26-16-16-44_0.csv";
import "../../Assets/css/download.css";

const EntitySmsReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // let [getPE] = useAdminPESMSMutation();
  let [getCounts] = useAdminSMScountsMutation();
  // let [urls, setUrls] = useState([]);
  let [counts, setCounts] = useState([]);
  // const [isToggle, setIsToggle] = useState("sms");

  useEffect(() => {
    if (localStorage.getItem("type") !== "Enterprise") {
      navigate("/");
    } else handleDomains();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // let toggle = (value) => {
  //   if (value === "sms") {
  //     setIsToggle("sms");
  //   } else {
  //     setIsToggle("scrub");
  //   }
  // };

  let handleDomains = () => {
    setIsLoading(true);
    // getPE()
    //   .then((success) => {
    //     if (success?.data?.statusCode === 200) {
    //       setIsLoading(false);
    //       setUrls(success.data.data);
    //     } else if (success.error.originalStatus > 300) {
    //       setIsLoading(false);
    //     } else if (success.data.statusCode > 300) {
    //       setIsLoading(false);
    //     }
    //   })
    //   .catch((error) => {
    //     setIsLoading(false);
    //     toast.error(error.status);
    //   });

    getCounts()
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          setCounts(success.data.data);
        } else if (success.error.originalStatus > 300) {
          setIsLoading(false);
        } else if (success.data.statusCode > 300) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.status);
      });
  };

  let handleTableRow = (row, index) => {
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row.chain}</td>
        <td>{row.success_count}</td>
        <td>{row.failure_count}</td>
        <td>{Number(row.failure_count) + Number(row.success_count)}</td>
        <td>
          {
            <a
              className="material-symbols-outlined"
              style={{ cursor: "pointer", color: "#6e6e73", textDecoration: "none" }}
              href={`${process.env.REACT_APP_API_SCRUB}/api/v1/admin/dashboard/download/${row.chain}`}
            >
              download_2
            </a>
          }
        </td>
      </tr>
    );
  };

  // const handleDownload = () => {
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = ExampleCDR;
  //   downloadLink.download = "Sample CDR.csv"; // Provide the desired filename for download
  //   downloadLink.click();
  // };

  // const [isLoadings, setIsLoadings] = useState(false);
  // const [isDone, setIsDone] = useState(false);

  // const handleClick = () => {
  //   setIsLoadings(true);
  //   setTimeout(() => {
  //     setIsDone(true);
  //     setTimeout(() => {
  //       setIsLoadings(false);
  //       setIsDone(false);
  //     }, 4000); // Adjust timeout according to your needs
  //   }, 1000);
  // };
  return (
    <EntityPrivateLayout>
      {isLoading && <Loaders />}
      {/* <div className="mt-3 mb-0 Dash_header"> */}
        {/* <span
          className="add_btn1 cursor ms-auto tooltip_cust"
          onClick={() => handleDownload()}
        >
          <div
            className={`btn-circle-download ${isLoadings ? "load" : ""} ${
              isDone ? "done" : ""
            }`}
            onClick={handleClick}
          >
            <svg id="arrow" width="14px" height="20px" viewBox="17 14 14 20">
              <path d="M24,15 L24,32"></path>
              <polyline points="30 27 24 33 18 27"></polyline>
            </svg>
            <svg id="check" width="21px" height="15px" viewBox="13 17 21 15">
              <polyline points="32.5 18.5 20 31 14.5 25.5"></polyline>
            </svg>
            <svg id="border" width="48px" height="48px" viewBox="0 0 48 48">
              <path d="M24,1 L24,1 L24,1 C36.7025492,1 47,11.2974508 47,24 L47,24 L47,24 C47,36.7025492 36.7025492,47 24,47 L24,47 L24,47 C11.2974508,47 1,36.7025492 1,24 L1,24 L1,24 C1,11.2974508 11.2974508,1 24,1 L24,1 Z"></path>
            </svg>
          </div>
          <p onClick={handleClick}>Download</p>
          <span className="tooltiptext">Download Sample CDR File.</span>
        </span> */}
        {/* <p className="ms-auto mb-1 px-3">Note: CDR updates every 30 mins.</p> */}
      {/* </div> */}

      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        
        <span className="top_border_vlt">
          <p>SMS Reports</p>
        </span>
        <div className="table_fixhead max">
          
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Chain</th>
                  <th>Success SMS</th>
                  <th>Failed SMS</th>
                  <th>Total SMS</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {counts.length > 0 ? (
                  counts.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td
                      colSpan="100%"
                      style={{
                        textAlign: "center",
                        paddingTop: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          
        </div>
      </div>
    </EntityPrivateLayout>
  );
};

export default EntitySmsReport;
