import React from "react";
import PropTypes from 'prop-types'; // Import PropTypes for props validation
import CountUp from "react-countup";

export default function Counter({ number, title }) {
  return (
    <div className="number">
      <CountUp duration={2} useEasing={true} className="counter fade-in" end={number} />
      <span>{title}</span>
    </div>
  );
}

// Add prop types validation
Counter.propTypes = {
  number: PropTypes.number.isRequired, // 'number' prop is required and should be a number
  title: PropTypes.string.isRequired, // 'title' prop is required and should be a string
};
