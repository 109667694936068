import React, { useState, useEffect } from "react";
import { EntityPrivateLayout } from "../../layouts/EntityPrivateLayout";
import {
  usePeSearchByCharacterMutation,
  usePeGetTMLISTMutation,
  usePeRouteHashListMutation
} from "../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import EntityApproveModal from "../../Components/entity_modal_approve";
import EntityRejectModal from "../../Components/entity_modal_reject";
import ModalPrivateKey from "../../Components/modal_private_key";
import copy from "copy-to-clipboard";
import "../../Assets/css/bindingButton.css"

const EntityBinding = () => {
  let [getData] = usePeSearchByCharacterMutation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isToggle, setIsToggle] = useState("route");
  const [filterValue] = useState("R")
  const handleOpenModal = () => { navigate("/entitymanage-route/entitybindingpartner") }
  const [tableData, setTableData] = useState([])
  const [status, setStatus] = useState("U")
  const [id, setID] = useState()
  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [name, setName] = useState()
  const [getTmName] = usePeGetTMLISTMutation();
  const [hastList] = usePeRouteHashListMutation();
  const [hashData, setHashData] = useState([]);
  const [keyTmId, setKeyTMId] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [search, setSearch] = useState("")


  useEffect(() => {
    if (localStorage.getItem("type") !== "Enterprise") {
      navigate("/")
    } else {
      if (isToggle === "route") {
        hash();
      } else
        handleDomains();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, isToggle, status, keyTmId, isActive]);

  // const selectFilter = (e) => {
  //   setFilterValue(e.target.value);
  //   setTableData([])
  // }

  const hash = () => {
    hastList()
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          // toast.success(success.data.message, { autoClose: 3000 });
          setHashData(success.data.data)
        } else if (success?.data?.statusCode > 300) {
          // toast.error(success?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  let handleDomains = () => {
    let postData = {}

    if (isToggle === "rejected") {
      postData = {
        "id": localStorage.getItem("id"),
        "reqType": filterValue === "R" ? "rejected" : "revoke",
        "search": search,
        "dbName": "enroll",
        "accSort": -1,
        "limit": 25,
        "pageNo": 1,
        "sts": filterValue
      };
    }

    else {
      postData =
      {
        "id": localStorage.getItem("id"),
        "search": search,
        "dbName": "enroll",
        "accSort": -1,
        "limit": 25,
        "pageNo": 1,
        "sts": status,
        "reqType": isToggle
      }
    }

    getData(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          // toast.success(success.data.message, { autoClose: 3000 });
          setTableData(success.data.data.data)
        } else if (success?.data?.statusCode > 300) {
          // toast.error(success?.data?.message);
          setTableData("")

        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };


  const copyText = (privateKey) => {
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 265);
    copy(privateKey);
    toast.success(`ID Copied: "${privateKey}"`);
  };
  let handleTableRow = (row, index) => {
    const renderApprovalDoc = (approvalDoc) => {
      return (
        <td>
          {approvalDoc ? (
            <a href={approvalDoc} rel="noopener noreferrer" target="_blank">
              <span className="material-symbols-outlined">note_stack</span>
            </a>
          ) : (
            "NA"
          )}
        </td>
      );
    };

    const renderApproveButtons = (row) => {
      return (
        <td>
          <button className="approve_btn me-4" value={row.tid} onClick={(e) => approve(e)}>
            Approve
          </button>
          <button className="revoke_btn" value={row.tid} onClick={(e) => reject(e)}>
            Reject
          </button>
        </td>
      );
    };

    const renderTimestamp = (timestamp) => {
      return (
        <td>{new Date(timestamp * 1000).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false }).replace(/,/, '')}</td>
      );
    };

    const renderRejectedRow = () => {
      return (
        <tr key={index}>
          <td>{++index}</td>
          <td>{row.tid}</td>
          <td>{row.tname}</td>
          {renderApprovalDoc(row.approvalDoc)}
          <td>{row.remarks}</td>
          {renderTimestamp(row.cts)}
        </tr>
      );
    };

    const renderRouteRow = () => {
      return (
        <tr key={index}>
          <td>{++index}</td>
          <td>{row.hashChain}</td>
          <td>
            <Link className="material-symbols-outlined" style={{ color: "blue", textDecoration: "none"}} to={`/entitymanage-route/view-details`} state={row.hashChain}>
              read_more
            </Link>
          </td>
          <td className="copy_col">
            {keyTmId === row._id + true ? (
              <div className={`copy-container ${isActive ? 'active' : ''}`}>
                <span>{"..." + row.privateKey.slice(-33)}</span>
                <span className="material-symbols-outlined copy-icon" onClick={() => copyText(row.privateKey)}>
                  content_copy
                </span>
              </div>
            ) : (
              <div className="material-symbols-outlined ms-3" style={{ color: "blue", cursor: "pointer" }} onClick={() => { getPrivateKey(row._id) }}>
                key
              </div>
            )}
          </td>
         
        </tr>
      );
    };

    switch (isToggle) {
      case "requested":
        return (
          <tr key={index}>
            <td>{++index}</td>
            <td>{row.tid}</td>
            <td>{row.tname}</td>
            {renderApprovalDoc(row.approvalDoc)}
          </tr>
        );

      case "pending":
        return (
          <tr key={index}>
            <td>{++index}</td>
            <td>{row.tid}</td>
            <td>{row.tname}</td>
            {renderApprovalDoc(row.approvalDoc)}
            {renderTimestamp(row.cts)}
            {renderApproveButtons(row)}
          </tr>
        );

      case "approved":
        return (
          <tr key={index}>
            <td>{++index}</td>
            <td>{row.tid}</td>
            <td>{row.tname}</td>
            {renderApprovalDoc(row.approvalDoc)}
            {renderTimestamp(row.cts)}
            <td>{row.hashId ? row.hashId : "NA"}</td>
          </tr>
        );

      case "rejected":
        return filterValue === "R" ? renderRejectedRow() : null;

      case "route":
        return renderRouteRow();

      default:
        return null;
    }
  };


  const getPrivateKey = (id) => {
    setOpenModal(true)
    setKeyTMId(id)
  }

  const approve = (e) => {
    const postData = { id: e.target.value }
    getTmName(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setName(success.data.data[0].name)
          toast.success(success.data.message, { autoClose: 3000 });
        } else if (success?.data?.statusCode > 300) {
          toast.error(success?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
    setID(e.target.value)
    setApproveModal(true)
  }
  const reject = (e) => {
    const postData = { id: e.target.value }
    getTmName(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setName(success.data.data[0].name)
          toast.success(success.data.message, { autoClose: 3000 });
        } else if (success?.data?.statusCode > 300) {
          toast.error(success?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
    setID(e.target.value)
    setRejectModal(true)
  }

  const toggle = (value, status) => {
    // if(value==="route"){
    //   hash();
    // }
    setIsToggle(value)
    setTableData([])
    setStatus(status)
    if (value !== isToggle) setSearch("")
  }



  return (
    <EntityPrivateLayout>
      {/* {isLoading && <Loaders/>} */}
      <div className="mt-3 Dash_header">
        <div className="buttons">
          <div className="wrapper">
            <div className="searchBar">
              {isToggle !== "route" &&
                <>
                  <input
                    id="searchQueryInput"
                    type="text"
                    name="searchQueryInput"
                    placeholder="Search by TM ID/Name"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}
                  />
                  <button
                    id="searchQuerySubmit"
                    type="submit"
                    name="searchQuerySubmit"
                  >
                    <span>
                      <svg
                        style={{ width: "24px", height: "24px" }}
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#8153A2"
                          d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                        />
                      </svg>
                    </span>
                  </button>
                </>
              }
            </div>
          </div>
        </div>
        <span className="cursor ms-auto glow-on-hover" onClick={handleOpenModal}>
          <span className="material-symbols-outlined btn_anim">library_add</span>
          <p>Binding Route Request</p>
        </span>
      </div>

      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <div className="switch-wrapper">
          <input id="request" type="radio" name="switch" />
          <input id="approved" type="radio" name="switch" />
          <input id="reject" type="radio" name="switch" />
          <input id="action" type="radio" name="switch" />
          <input id="revoke" type="radio" name="switch" defaultChecked />

          <label
            className="one_fifth_w curve_left"
            htmlFor="revoke"
            onClick={() => toggle("route", "U")}
          >
            Routes
          </label>
          <label
            className="one_fifth_w "
            htmlFor="request"
            onClick={() => toggle("requested", "P")}
          >
            Telemarketer Status
          </label>
          <label
            className="one_fifth_w"
            htmlFor="approved"
            onClick={() => toggle("pending", "P")}
          >
            Entity Status
          </label>
          <label
            className="one_fifth_w"
            htmlFor="reject"
            onClick={() => toggle("approved", "A")}
          >
            Approved Telemarketers
          </label>
          <label
            className="one_fifth_w curve_right"
            htmlFor="action"
            onClick={() => toggle("rejected", "R")}
          >
            Rejected Telemarketers
          </label>

          <span className="highlighter"></span>
        </div>

        <div className="table_fixhead">
          {isToggle === "requested" && (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Telemarketer Id</th>
                  <th>Telemarketer Name</th>
                  <th>Doc Uploaded</th>
                </tr>
              </thead>
              <tbody>
                {tableData.length > 0 ? (
                  tableData.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {isToggle === "pending" && (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Telemarketer Id</th>
                  <th>Telemarketer Name</th>
                  <th>Doc Uploaded</th>
                  <th>Date & Time of Application</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tableData.length > 0 ? (
                  tableData.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {(isToggle === "approved") && (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Telemarketer Id</th>
                  <th>Telemarketer Name</th>
                  <th>Doc Uploaded</th>
                  <th>Date & Time of Registration</th>
                  <th>Binding Hash</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.length > 0 ? (
                  tableData?.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          {(isToggle === "rejected" && filterValue === "R") && (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Telemarketer Id</th>
                  <th>Telemarketer Name</th>
                  <th>Doc Uploaded</th>
                  <th>Reason for Rejection</th>
                  <th>Date & Time of Status Updated</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.length > 0 ? (
                  tableData?.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {(isToggle === "rejected" && filterValue === "T") && (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Telemarketer Id</th>
                  <th>Telemarketer Name</th>
                  <th>Doc Uploaded</th>
                  <th>Reason for Rejection</th>
                  <th>Date & Time of Status Updated</th>
                  <th>Revoked By</th>

                </tr>
              </thead>
              <tbody>
                {tableData?.length > 0 ? (
                  tableData?.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {isToggle === "route" && (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Chain</th>
                  <th>View Details</th>
                  <th>Private Key</th>
                </tr>
              </thead>
              <tbody>
                {hashData?.length > 0 ? (
                  hashData?.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <EntityApproveModal
        open={approveModal}
        onClose={() => setApproveModal(false)}
        id={id}
        name={name}
      />
      <EntityRejectModal
        open={rejectModal}
        onClose={() => setRejectModal(false)}
        id={id}
        name={name}
      />

      {/* MODAL FOR PRIVATE KEY */}
      <ModalPrivateKey
        open={openModal}
        keyIdFalse={() => setKeyTMId(keyTmId + true)}
        onClose={() => setOpenModal(false)}
      />
    </EntityPrivateLayout>
  );
};

export default EntityBinding;
