import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as imgs from "../Assets/images";
import { useTmLoginMutation } from "../Services/services";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import PropTypes from 'prop-types';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "560px",
  padding: "60px",
  display: "inline-flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "30px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "44px",
  border: "2px solid #8153A2",
};

export default function ModalPrivateKey({ open, onClose, keyIdFalse }) {
  const [isLoading, setIsLoading] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  let [mappingData, setMappingData] = useState({});
  let [verifyUser] = useTmLoginMutation();

  let handleInputChanges = (e, state) => {
    let targetValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setMappingData({ ...mappingData, [[state]]: targetValue });
  };

  const submitURL = (e) => {
    let postData = {
      companyEmail: mappingData.email,
      password: window.btoa(mappingData.password),
      eclass: localStorage.getItem("type") === "Telemarketer" ? "TM" : "PE",
    }

    if (mappingData.email === "" || mappingData.email === undefined) {
      toast.error("Please provide your Email")
    } else if (mappingData.password === "" || mappingData.password === undefined) {
      toast.error("Please provide your Password")
    }
    else {
      setIsLoading(true);
      verifyUser(postData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setIsLoading(false);
            setMappingData({})
            setOpenSecondModal(true);
            toast.success("verified Successfully", { autoClose: 3000 });
            keyIdFalse()
            setOpenSecondModal(false);
            onClose()
          } else if (success.error.data.statusCode > 300) {
            toast.error(success.error.data.message);
            setIsLoading(false);
          } else if (success.data.statusCode > 300) {
            toast.error(success?.data?.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.message);
        });
    }

  };
  const handleCloseModal = (e) => {
    setMappingData({})
    setOpenSecondModal(false);
    onClose();
  };

  if (!open) return null;
  return (
    <>
      {!isLoading ? (<Modal
        open={open}
        onClose={(e) => handleCloseModal(e)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps = {{
          onClick: null, // Prevents closing when clicking outside the Modal
          }}
      >
        {!openSecondModal ? (
          <Box sx={style}>
            <h1 className="modal-head">Verify User</h1>
            <div className="input-group">
              <button
                className=" btn btn_prple"
                onClick={() => document.getElementById('emailInput').focus()}
              >
                Email
              </button>
              <input
                type="text"
                id="emailInput"
                className="input_mod form-control"
                placeholder="Your Email"
                onChange={(e) => handleInputChanges(e, "email")}
              />
            </div>

            <div className="input-group">
              <button
                className=" btn btn_prple"
                onClick={() => document.getElementById('password').focus()}
              >
                Password
              </button>
              <input
                type="password"
                id="password"
                className="input_mod form-control"
                placeholder="Your Password"
                onChange={(e) => handleInputChanges(e, "password")}
              />
            </div>


            <button className="login_btn cursor" onClick={() => submitURL()}>
              Verify
            </button>
            <button
              className="cancel_btn cursor"
              onClick={(e) => handleCloseModal(e)}
            >
              Cancel
            </button>
          </Box>
        ) : (
          <Box sx={style}>
            <h1 className="modal-head">User Verification done Successfully</h1>
            <img src={imgs.modalBg} alt="done" />

            <p>
              Your Verification done successfully now you are able to see
              the Private key of that specific Telemarketer.
            </p>
            <button
              className="cancel_btn cursor"
              onClick={(e) => handleCloseModal(e)}
            >
              Done
            </button>
          </Box>
        )}
      </Modal>) : (
        <Modal
          open={open}
          onClose={(e) => handleCloseModal(e)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps = {{
            onClick: null, // Prevents closing when clicking outside the Modal
            }}
        >
          <Box sx={style}>
            <h1 className="modal-head">User Verification Status</h1>
            <img src={imgs.modalBg} alt="done" />

            <p>
              User Verifying...
            </p>
          </Box>
        </Modal>
      )}

    </>
  );
}

ModalPrivateKey.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  keyIdFalse: PropTypes.func.isRequired,
};