import React, { useState } from "react";
import PropTypes from 'prop-types'; // Import PropTypes for props validation
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as imgs from "../Assets/images";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "560px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "44px",
  border: "2px solid #8153A2",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "30px",
  padding: "60px"
}
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "560px",
  padding: "21px 15px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "44px",
  border: "2px solid #8153A2",
};

export default function BulkModal({ open, onClose }) {
  const [isLoading, setIsLoading] = useState(false);

  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState();


  // sends URL through API
  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const submitURL = () => {
    setIsLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + JSON.parse(localStorage.getItem("authState")).user);

    const formdata = new FormData();
    formdata.append("csvFile", selectedFiles[0], "bulkurlregsample.csv");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_PORT}/api/v1/urlRegistration/bulkUrlReg`, requestOptions)
      .then(response => {
        setIsLoading(false);
        response.text();
        setOpenSecondModal(true);
      })
      .catch(error => {toast.error(error.message); setIsLoading(false);});
  };

  const handleCloseModal = () => {
    setOpenSecondModal(false);
    onClose();
  };

  if (!open) return null;
  return (
    <>
      {!isLoading ? (
        <Modal
          open={open}
          onClose={(e) => handleCloseModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            onClick: null, // Prevents closing when clicking outside the Modal
          }}
        >
          {!openSecondModal ? (
            <Box sx={style2}>
              <div className="dashed_box">
                <img src={imgs.upload} alt="upload file" />
                <h1 className="modal-head" style={{ color: "#8153A2" }}>
                  Drop your file here
                </h1>
                <a className="sqr_btn cursor" href={`${process.env.REACT_APP_SAMPLES_IP}/bulkurlregsample.csv`} target="blank">
                  <span className="material-symbols-outlined">file_save</span>
                  <p className="m-0">Download Sample</p>
                </a>

                <div>
                  <input
                    type="file"
                    className="mb-2"
                    onChange={(e) => handleFileChange(e)}
                    accept=".csv"
                  />
                  <p className="m-0 text-center">Note : Upload CSV file only</p>
                </div>
                <button
                  className="login_btn cursor"
                  onClick={() => submitURL()}
                >
                  Submit
                </button>
                <button
                  className="cancel_btn cursor"
                  onClick={(e) => handleCloseModal()}
                >
                  Cancel
                </button>
              </div>
            </Box>
          ) : (
            <Box sx={style}>
              <h1 className="modal-head">Add URL Request Sent Successfully</h1>
              <img src={imgs.modalBg} alt="done" />

              <p>Your URL list has been uploaded successfully.</p>
              <button
                className="cancel_btn cursor"
                onClick={(e) => handleCloseModal()}
              >
                Done
              </button>
            </Box>
          )}
        </Modal>
      ) : (
        <Modal
          open={open}
          onClose={(e) => handleCloseModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            onClick: null, // Prevents closing when clicking outside the Modal
          }}
        >
          <Box sx={style}>
            <h1 className="modal-head">Add URL Request Sent Successfully</h1>
            <img src={imgs.modalBg} alt="done" />

            <p>URL List Adding...</p>
          </Box>
        </Modal>
      )}
    </>
  );
}

// Add prop types validation
BulkModal.propTypes = {
  open: PropTypes.bool.isRequired, // 'open' prop is required and should be a boolean
  onClose: PropTypes.func.isRequired, // 'onClose' prop is required and should be a function
};
