import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from '@mui/material/Fade';
import * as imgs from "../Assets/images";
import PropTypes from 'prop-types';
import { useAddURLMapMutation, useVerifyIdMutation } from "../Services/services";
import VerifyLoader from "../Assets/loaders/verifyLoader";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "590px",
  padding: "60px",
  display: "inline-flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "30px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "44px",
  border: "2px solid #8153A2",
};

const InputForm = ({ mappingData, handleInputChanges, verify, isVerify, verificationStatus }) => {
  const verifyButtonText = verificationStatus ? 'Verified' : 'Verify';
  const verifyButtonContent = isVerify ? <VerifyLoader /> : verifyButtonText;
  const verifyButtonClassName = `btn ${verificationStatus ? 'btn-success' : 'btn btn_prple'}`;

  return (
    <>
      <h1 className="modal-head">Map URL</h1>
      <div className="input-group">
        <input
          type="text"
          className="input_mod form-control"
          placeholder="Template ID"
          value={mappingData.template_id || ""}
          onChange={(e) => handleInputChanges(e, "template_id")}
          disabled={verificationStatus}
        />
        <button
          className={verifyButtonClassName}
          onClick={verify}
          disabled={isVerify || verificationStatus}
        >
          {verifyButtonContent}
        </button>
      </div>
      <textarea
        type="text"
        className="inputURL"
        placeholder="Paste URL Here"
        value={mappingData.url || ""}
        onChange={(e) => handleInputChanges(e, "url")}
        rows={4}
      />
      <p className="m-0 fs-sm">Note: Only whitelisted URLs will be mapped with the template-ID.</p>
    </>
  );
};


InputForm.propTypes = {
  mappingData: PropTypes.object.isRequired,
  verify: PropTypes.func.isRequired,
  isVerify: PropTypes.bool.isRequired, // Add this line
  verificationStatus: PropTypes.bool.isRequired,
  handleInputChanges: PropTypes.func.isRequired,
};

const LoadingState = ({ handleCloseModal }) => (
  <Fade in={true}>
    <Box sx={style}>
      <h1 className="modal-head">Add URL Mapping Request Sent Successfully</h1>
      <img src={imgs.modalBg} alt="done" />
      <p>Mapped URL Adding...</p>
      <button className="cancel_btn cursor" onClick={handleCloseModal}>Done</button>
    </Box>
  </Fade>
);

LoadingState.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};

const ModalContent = ({ open, handleCloseModal, openSecondModal, handleInputChanges, mappingData, verify, isVerify, verificationStatus, submitURL }) => (
  <Fade in={open}>
    <Box sx={style}>
      {!openSecondModal ? (
        <>
          <InputForm
            mappingData={mappingData}
            handleInputChanges={handleInputChanges}
            verify={verify}
            isVerify={isVerify}
            verificationStatus={verificationStatus}
          />
          <button className="login_btn cursor" onClick={submitURL}>Send Request</button>
          <button className="cancel_btn cursor" onClick={handleCloseModal}>Cancel</button>
        </>
      ) : (
        <>
          <h1 className="modal-head">Map URL Request Submitted Successfully</h1>
          <img src={imgs.modalBg} alt="done" />
          <p>Your URL has been mapped successfully</p>
          <button className="cancel_btn cursor" onClick={handleCloseModal}>Done</button>
        </>
      )}
    </Box>
  </Fade>
);

ModalContent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  openSecondModal: PropTypes.bool.isRequired,
  handleInputChanges: PropTypes.func.isRequired,
  mappingData: PropTypes.object.isRequired,
  verify: PropTypes.func.isRequired,
  isVerify: PropTypes.bool.isRequired, // Add this line
  verificationStatus: PropTypes.bool.isRequired,
  submitURL: PropTypes.func.isRequired,
};

export default function Modal2({ open, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [mappingData, setMappingData] = useState({ template_id: "", url: "" });
  const [addMap] = useAddURLMapMutation();
  const [verifyId] = useVerifyIdMutation();
  const [verificationStatus, setVerificationStatus] = useState(false);

  useEffect(() => { }, [verificationStatus]);

  const handleInputChanges = (e, state) => {
    const targetValue = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setMappingData({ ...mappingData, [state]: targetValue });
  };

  const verify = () => {
    const postData = {
      gsts: "A",
      search: mappingData.template_id,
      dbName: "ct",
      accSort: -1,
      pageNo: 1,
      limit: 25,
      id: localStorage.getItem("id"),
    };
    if (!mappingData.template_id) {
      toast.error("Please provide Template ID");
    } else if (!/^\d{1,19}$/.test(mappingData.template_id)) {
      toast.error("Template ID is not valid");
    } else {
      setIsVerify(true);
      verifyId(postData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setIsVerify(false);
            setVerificationStatus(true);
            toast.success("Template ID verified successfully", { autoClose: 3000 });
          } else if (success.data.statusCode > 300) {
            toast.error(success?.data?.message);
            setIsVerify(false);
          }
        })
        .catch((error) => {
          setIsVerify(false);
          toast.error(error.message);
        });
    }
  };

  const submitURL = () => {
    const postData = {
      templateId: mappingData.template_id,
      url: mappingData.url,
    };
    if (!verificationStatus) {
      toast.error("Verify your Template ID");
    } else if (!mappingData.url) {
      toast.error("Please provide URL");
    } else {
      setIsLoading(true);
      addMap(postData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            toast.success(success.data.message, { autoClose: 3000 });
            setIsLoading(false);
            setOpenSecondModal(true);
          } else {
            setIsLoading(false);
            toast.error(success.error?.data?.message || success.data?.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.message);
        });
    }
  };

  const handleCloseModal = () => {
    setMappingData({ template_id: "", url: "" });
    setVerificationStatus(false);
    setOpenSecondModal(false);
    onClose();
  };

  if (!open) return null;

  return (
    <>
      {!isLoading ? (
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            onClick: null, // Prevents closing when clicking outside the Modal
          }}
        >
          <ModalContent
            open={open}
            handleCloseModal={handleCloseModal}
            openSecondModal={openSecondModal}
            handleInputChanges={handleInputChanges}
            mappingData={mappingData}
            verify={verify}
            isVerify={isVerify}
            verificationStatus={verificationStatus}
            submitURL={submitURL}
          />
        </Modal>
      ) : (
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            onClick: null, // Prevents closing when clicking outside the Modal
          }}
        >
          <LoadingState handleCloseModal={handleCloseModal} />
        </Modal>
      )}
    </>
  );
}

Modal2.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};