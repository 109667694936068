import React from "react";
import { error } from "../../Assets/images";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Error() {
  const navigate = useNavigate();
  const home = () => {
    toast.success("GOING HOME", { autoClose: 2000 });
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div
        className="d-flex justify-content-center m-3"
        style={{ height: "100px" }}
      >
        <span
          style={{ fontSize: "45px", color: "#8153a2", cursor: "pointer" }}
          className="material-symbols-outlined me-5 "
        >
          support_agent
        </span>
        <span
          style={{ fontSize: "45px", color: "#8153a2", cursor: "pointer" }}
          className="material-symbols-outlined"
          onClick={() => {
            home();
          }}
        >
          home
        </span>
      </div>
      <div className="d-flex justify-content-center">
        <img alt="" src={error} style={{ width: "500px" }} />
      </div>
    </div>
  );
}
