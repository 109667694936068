import React, { useEffect, useState } from "react";
import { PrivateLayout } from "../../layouts/PrivateLayout";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Loaders from "../../Assets/loaders/loaders";
import { useComplaintsMutation } from "../../Services/services";

const Complaints = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  // const [isHovered, setIsHovered] = useState(false);
  // const [search, setSearch] = useState("");
  let [data, setData] = useState([]);

  let [getComplaints] = useComplaintsMutation();

  useEffect(() => {
    if (localStorage.getItem("type") !== "Telemarketer") {
      navigate("/");
    } else handleDomains();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleDomains = () => {
    const postData = {
      sts: "",
      accSort: -1,
      limit: 20,
      pageNo: 1,
      sortOn: "cts",
      peid: "",
    };
    setIsLoading(true);
    try {
      getComplaints(postData).then((success) => {
        setIsLoading(false);
        if (success.data.statusCode === 200) {
          setData(success.data.data.data);
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  let handleTableRow = (row, index) => {
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row.msisdn}</td>
        <td>{row.cli}</td>
        <td>{new Date(row.cts * 1000).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false }).replace(/,/, '')}</td>
        <td>{row.sts}</td>
        <td style={{maxWidth:"300px", overflow: "hidden", textOverflow: "ellipsis"}}>{row.desc}</td>
        <td>{row.uby}</td>
        <td>{row.tap}</td>
      </tr>
    );
  };
  return (
    <PrivateLayout>
       {isLoading && <Loaders />}
     
      <div className="data_layout_4">
        <span className="top_border_vlt">
          <p>UCC Complaints</p>
        </span>
        <div className="table_fixhead max">
          <table>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Phone No.</th>
                <th>Header ID</th>
                <th>Date</th>
                <th>Status</th>
                <th>Message</th>
                <th>OAP</th>
                <th>TAP</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((row, index) => handleTableRow(row, index))
              ) : (
                <tr>
                  <td
                    colSpan="100%"
                    style={{
                      textAlign: "center",
                      paddingTop: "200px",
                      fontWeight: "bold",
                    }}
                  >
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </PrivateLayout>
  );
};

export default Complaints;