import React, { useState, useEffect } from "react";
import {
    useAdminChainListMutation
} from "../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../../Assets/loaders/loaders";
import { AdminPrivateLayout } from "../../layouts/AdminPrivateLayout";
import { Link, useNavigate } from "react-router-dom";

const AdminRouteDetail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isToggle] = useState("whitelisted");
    const navigate = useNavigate();
    let [chains, setChains] = useState([]);

    let [getRegList] = useAdminChainListMutation();
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("type") !== "ADMIN") {
            navigate("/admin-login")
        } else
            handleDomains();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isToggle]);

    let handleDomains = () => {
        setIsLoading(true);
        getRegList()
            .then((success) => {
                if (success?.data?.statusCode === 200) {
                    setChains(success?.data?.data)
                    setIsLoading(false);
                } else if (success.error.data.statusCode > 300) {
                    setIsLoading(false);
                } else if (success.data.statusCode > 300) {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error.message);
            });
    };

    const handleMouse = (e, rowId) => {
        if (e.type === "mouseenter") {
            setIsHovered(`${rowId}`);
        } else {
            setIsHovered("");
        }
    };

    let handleTableRow = (row, index) => {
        const rowId = row.hashChain; 
        return (
            <tr key={index}>
                <td>{++index}</td>
                <td
                    onMouseEnter={(e) => handleMouse(e, rowId)}
                    onMouseLeave={(e) => handleMouse(e, rowId)}
                    id={`${rowId}`}
                    className="w-75"
                >
                    {isHovered === `${rowId}` ? (
                        <Link to={`/admin-route-details/routes-inside`} state={row.hashChain}>
                            <button className="view_btn w-25">View more...</button>
                        </Link>
                     
                    ) : (
                        row.hashChain
                    )}
                </td>
            </tr>
        );
    };
    return (
        <AdminPrivateLayout>
            {isLoading && <Loaders />}

            {/* ------------------------------------data table----------------------------------- */}
            <div className="data_layout_4">
                <span className="top_border_vlt">
                    <p>Routes Detailed List</p>
                </span>
                <div className="table_fixhead max">
                    <table>
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                <th>Chain</th>
                            </tr>
                        </thead>
                        <tbody>
                            {chains.length > 0 ? (
                                chains.map((row, index) => handleTableRow(row, index))
                            ) : (
                                <tr>
                                    <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </AdminPrivateLayout>
    );
};

export default AdminRouteDetail;
