import React, { useState, useEffect } from "react";
import CustomModal from "../../Components/modal_reg";
import BulkModal from "../../Components/entityBulkUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useListRegMutation,
  useEditRegMutation,
  // useEnableRegMutation,
  useDeleteRegMutation,
} from "../../Services/services";
import { EntityPrivateLayout } from "../../layouts/EntityPrivateLayout";
import Loaders from "../../Assets/loaders/loaders";
import { useNavigate } from "react-router-dom";

const EntityRegistration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let [getRegList] = useListRegMutation();
  let [edit] = useEditRegMutation();
  let [del] = useDeleteRegMutation();

  const [isToggle, setIsToggle] = useState("whitelisted");

  const [openModal1, setOpenModal1] = useState(false);
  const handleOpenModal1 = () => setOpenModal1(true);
  const [openModal2, setOpenModal2] = useState(false);
  const handleOpenModal2 = () => setOpenModal2(true);
  let [urls, setUrls] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("type") !== "Enterprise") {
      navigate("/")
    } else
      handleDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToggle, openModal1, openModal2]);

  let handleDomains = () => {
    const postData = {
      urlType: isToggle,
      part: "",
    };
    setIsLoading(true);
    try {
      getRegList(postData).then((success) => {
        if (success.data.statusCode === 200) {
          setUrls(success.data.data);
          setIsLoading(false);
        } else {
          setIsLoading(true);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };


  let handleTableRow = (row, index) => {
    if (isToggle === "whitelisted") {
      return (
        <tr key={index}>
          <td>{++index}</td>
          <td>{row.url}</td>
          <td>{row.url_status}</td>
          <td>{row.code}</td>
          <td>
            {new Date(row.createdAt).toLocaleDateString("en-US", {
              day: "numeric",
              month: "short",
            })}{" "}
            {new Date(row.createdAt).getFullYear()},{" "}
            {new Date(row.createdAt).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })}
          </td>
          <td>
            {new Date(row.updatedAt).toLocaleDateString("en-US", {
              day: "numeric",
              month: "short",
            })}{" "}
            {new Date(row.updatedAt).getFullYear()},{" "}
            {new Date(row.updatedAt).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })}
          </td>
          {/* <td>
            <label className="switch">
              <input
                type="checkbox"
                checked={row.status === "ACTIVE"}
                onChange={() => handleToggleStatus(row._id, row.status)}
              />
              <span className="slider round"></span>
            </label>
          </td> */}
          <td>
            <span
              className="material-symbols-outlined me-4"
              style={{ cursor: "pointer", color: "#E74E37" }}
              onClick={() => handleDeleteClick(row._id)}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Delete"
            >
              delete
            </span>
            <span
              className="material-symbols-outlined "
              style={{ cursor: "pointer", color: "purple" }}
              onClick={() =>
                handleSwapClick(row._id, row.urlType, row.url, row.part)
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={
                isToggle === "whitelisted"
                  ? "Move to Blacklist"
                  : "Move to Whitelist"
              }
            >
              published_with_changes
            </span>
          </td>
        </tr>
      );
    } else {
      return (
        <tr key={index}>
          <td>{++index}</td>

          <td>{row.url}</td>
          <td>{row.url_status}</td>
          <td>{row.code}</td>

          <td>
            {new Date(row.createdAt).toLocaleDateString("en-US", {
              day: "numeric",
              month: "short",
            })}{" "}
            {new Date(row.createdAt).getFullYear()},{" "}
            {new Date(row.createdAt).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })}
          </td>
          <td>
            {new Date(row.updatedAt).toLocaleDateString("en-US", {
              day: "numeric",
              month: "short",
            })}{" "}
            {new Date(row.updatedAt).getFullYear()},{" "}
            {new Date(row.updatedAt).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })}
          </td>

          <td>
            <span
              className="material-symbols-outlined me-4"
              style={{ cursor: "pointer", color: "#E74E37" }}
              onClick={() => handleDeleteClick(row._id)}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Delete"
            >
              delete
            </span>
            <span
              className="material-symbols-outlined "
              style={{ cursor: "pointer", color: "purple" }}
              onClick={() =>
                handleSwapClick(row._id, row.urlType, row.url, row.part)
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={
                isToggle === "whitelisted"
                  ? "Move to Blacklist"
                  : "Move to Whitelist"
              }
            >
              published_with_changes
            </span>
          </td>
        </tr>
      );
    }
  };

  let toggle = (value) => {
    if (value === "White") {
      setIsToggle("whitelisted");
    } else {
      setIsToggle("blacklisted");
    }
  };

  // enable/disable functionality
  // const [enable] = useEnableRegMutation();
  // const handleToggleStatus = (rowId, currentStatus) => {
  //   const newStatus = currentStatus === "ACTIVE" ? "DISABLED" : "ACTIVE";
  //   const postData = {
  //     _id: rowId,
  //   };
  //   setIsLoading(true);
  //   enable(postData)
  //     .then((success) => {
  //       if (success?.data?.statusCode === 200) {
  //         setIsLoading(false);
  //         toast.success(
  //           `${newStatus === "ACTIVE" ? "Enabled" : "Disabled"} successfully`
  //         );
  //         handleDomains();
  //       } else if (success.error.data.statusCode > 300) {
  //         setIsLoading(false);
  //       } else if (success.data.statusCode > 300) {
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       toast.error(error.message);
  //     });
  // };

  //TO SWAP URL TYPE
  const handleSwapClick = (rowId, urlType, url, part) => {
    const postData = {
      _id: rowId,
      urlType: urlType === "whitelisted" ? "blacklisted" : "whitelisted",
      part: part,
      url: url,
    };
    setIsLoading(true);
    edit(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          toast.success(success.data.message);
          // setEditableRowIndex(null);
          handleDomains();
        } else if (success.error.data.statusCode > 300) {
          setIsLoading(false);
        } else if (success.data.statusCode > 300) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  // TO DELETE
  const handleDeleteClick = (rowId) => {
    const postData = {
      _id: rowId,
    };
    setIsLoading(true);
    del(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          toast.success(success.data.message);
          // setEditableRowIndex(null);
          handleDomains();
        } else if (success.error.data.statusCode > 300) {
          setIsLoading(false);
        } else if (success.data.statusCode > 300) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  return (
    <EntityPrivateLayout>
      {isLoading && <Loaders />}
      <div className="mt-3 Dash_header">
        {/* <div className="buttons">
          <div className="wrapper">
            <div className="label">Search</div>
            <div className="searchBar">
              <input
                id="searchQueryInput"
                type="text"
                name="searchQueryInput"
                placeholder="Search by PE ID / Sender ID"
                // value=""
              />
              <button
                id="searchQuerySubmit"
                type="submit"
                name="searchQuerySubmit"
              >
                <span>
                  <svg
                    style={{ width: "24px", height: "24px" }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#8153A2"
                      d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>

          <div className="wrapper filter">
            <div className="label" htmlFor="filter">
              Filters
            </div>

            <select name="filter" id="filter" className="filter_options">
              <option value="all">All</option>
              <option value="white">White</option>
              <option value="black">Black</option>
            </select>
          </div>

          <div className="wrapper records">
            <div className="label" htmlFor="filter">
              Show Records
            </div>

            <select name="filter" id="filter" className="filter_options">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="10">10</option>
            </select>
          </div>
        </div> */}
        <span className="add_btn ms-auto me-3 cursor glow-on-hover" onClick={handleOpenModal1}>
          <span className="material-symbols-outlined btn_anim">add_circle</span>
          <p>Add URL</p>
        </span>
        <span className="add_btn cursor glow-on-hover" onClick={handleOpenModal2}>
          <span className="material-symbols-outlined btn_anim">add_circle</span>
          <p>Add Bulk URL</p>
        </span>
      </div>

      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <div className="switch-wrapper">
          <input id="white" type="radio" name="switch" defaultChecked />
          <input id="black" type="radio" name="switch" />
          <label
            htmlFor="white"
            onClick={() => toggle("White")}
            className="curve_left"
          >
            Whitelisted URLs
          </label>
          <label
            htmlFor="black"
            onClick={() => toggle("Black")}
            className="curve_right"
          >
            Blacklisted URLs
          </label>
          <span className="highlighter"></span>
        </div>
        <div className="table_fixhead">
          {isToggle === "whitelisted" ? (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>White URL</th>
                  <th>Status</th>
                  <th>Code</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {urls.length > 0 ? (
                  urls.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td
                      colSpan="100%"
                      style={{
                        textAlign: "center",
                        paddingTop: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Black URL</th>
                  <th>Status</th>
                  <th>Code</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {urls.length > 0 ? (
                  urls.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td
                      colSpan="100%"
                      style={{
                        textAlign: "center",
                        paddingTop: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {/* model calling */}
      <CustomModal open={openModal1} onClose={() => setOpenModal1(false)} />
      <BulkModal open={openModal2} onClose={() => setOpenModal2(false)} />
    </EntityPrivateLayout>
  );
};

export default EntityRegistration;
