import React, { useState, useEffect } from "react";
import {
  useAdminIPListMutation,
} from "../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../../Assets/loaders/loaders";
import { AdminPrivateLayout } from "../../layouts/AdminPrivateLayout";
import { Link, useNavigate } from "react-router-dom";
const AdminIPDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isToggle] = useState("whitelisted");
  const navigate = useNavigate();
  let [urls, setUrls] = useState([]);

  let [getRegList] = useAdminIPListMutation();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("type") !== "ADMIN") {
      navigate("/admin-login")
    } else
      handleDomains();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToggle]);

  let handleDomains = () => {
    const postData = {
      ipType: isToggle,
    };
    setIsLoading(true);
    getRegList(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          setUrls(success.data.data.ipRegistrationList);
        } else if (success.error.data.statusCode > 300) {
          setIsLoading(false);
        } else if (success.data.statusCode > 300) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  const handleMouse = (e, type, rowId) => {
    if (e.type === "mouseenter") {
      setIsHovered(`${type}-${rowId}`);
    } else {
      setIsHovered("");
    }
  };

  let handleTableRow = (row, index) => {
    const rowId = row._id; // Assuming _id is unique for each row
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row._id}</td>
        <td>{row.tname}</td>
        <td
          onMouseEnter={(e) => handleMouse(e, "white", rowId)}
          onMouseLeave={(e) => handleMouse(e, "white", rowId)}
          id={`whitelisted-${rowId}`}
        >
          {isHovered === `white-${rowId}` ? (
            <Link to={`/admin-ip/${"whitelisted"}/${rowId}`}>
              <button className="view_btn">View more...</button>
            </Link>
          ) : (
            row.whitelistCount
          )}
        </td>
        <td
          onMouseEnter={(e) => handleMouse(e, "black", rowId)}
          onMouseLeave={(e) => handleMouse(e, "black", rowId)}
          id={`blacklisted-${rowId}`}
        >
          {isHovered === `black-${rowId}` ? (
            <Link to={`/admin-ip/${"blacklisted"}/${rowId}`}>
              <button className="view_btn">View more...</button>
            </Link>
          ) : (
            row.blacklistCount
          )}
        </td>
        <td>{row.totalCount}</td>
      </tr>
    );
  };
  return (
    <AdminPrivateLayout>
      {isLoading && <Loaders />}

      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <span className="top_border_vlt">
          <p>IP Details List</p>
        </span>
        <div className="table_fixhead max">
          <table>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Telemarketer ID</th>
                <th>Telemarketer Name</th>
                <th>Whitelisted IP</th>
                <th>Blacklisted IP</th>
                <th>Total IP</th>
              </tr>
            </thead>
            <tbody>
              {urls.length > 0 ? (
                urls.map((row, index) => handleTableRow(row, index))
              ) : (
                <tr>
                  <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </AdminPrivateLayout>
  );
};

export default AdminIPDetail;
