import React, { useEffect, useState } from "react";
import { PrivateLayout } from "../../../layouts/PrivateLayout";
import {
  useTmViewDetailsMutation,
  useTmSubmitBindingRequestMutation
} from "../../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Loaders from "../../../Assets/loaders/loaders";

const BindingPartner = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState("");
  const [selectPartner, setSelectPartner] = useState();
  const [viewDetails, setViewDetails] = useState(false);
  const [detailsView] = useTmViewDetailsMutation();
  const [submitRequest] = useTmSubmitBindingRequestMutation();
  const navigate = useNavigate();
  const [containData, setContainData] = useState({})
  const [fileData, setFileData] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("type") !== "Telemarketer") {
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const postData = {
    eclass: selectPartner === "Telemarketer" ? "TM" : "",
    id: id
  }

  const viewDetail = () => {
    if (id === "") {
      toast.error("Please Provide TM ID")
    } else {
      setIsLoading(true);
      detailsView(postData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setIsLoading(false);
            toast.success(success?.data?.message, { autoClose: 2000 });
            setViewDetails(true);
            setContainData(success?.data?.data)

          } else if (success?.data?.statusCode > 300) {
            setIsLoading(false);
            setViewDetails(false);
            toast.error(success?.data?.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setViewDetails(false);
          toast.error(error.message);
        });
    }
  };

  const submitPostData =
  {
    peid: id,
    tmid: localStorage.getItem("id"),
    peApprovalDoc: fileData,
    crtrTmName: localStorage.getItem("name"),
  }
  const submit = () => {
    if (!fileData.length > 0) {
      toast.error("Please Upload the file")
    } else {
      setIsLoading(true);
      submitRequest(submitPostData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setIsLoading(false);
            toast.success(success?.data?.message, { autoClose: 2000 });
            navigate("/manage-route");

          } else if (success?.data?.statusCode > 200) {
            setIsLoading(false);
            toast.error(success?.data?.message);
          }
          else if (success?.data?.Status > 200) {
            setIsLoading(false);
            toast.error(success?.data?.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.message);
        });
    }
  };

  const goBack = () => {
    navigate("/manage-route");
  };


  const fileInput = (e) => {
    const formData = new FormData();
    formData.append(`doc`, e.target.files[0]);

    fetch(`${process.env.REACT_APP_API_PORT_DLT}/entity/uploadFile`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setFileData(data.data)
      })
      .catch((err) => toast.error("Error", err));
  };

  return (
    <PrivateLayout>
      {isLoading && <Loaders />}
      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <div className="top_border_vlt">
          <h5 className="ms-auto m-0">Binding Request</h5>
          <span
            className="material-symbols-outlined cursor ms-auto"
            onClick={() => goBack()}
          >
            arrow_back
          </span>
        </div>

        <div className="binding_col mt-5">
          <div className="d-flex align-items-center mb-3 col-md-6">
            <div htmlFor="filter" className="col-md-5 mt-2">
              Choose Telemarketing Partner:
            </div>
            <select
              name="list"
              id="selectBox"
              style={{ backgroundColor: "#F8F8F9" }}
              className="filter_options col-md-7 select_lg"
              onClick={(e) => setSelectPartner(e.target.value)}
            >
              <option value="">--Select--</option>
              {/* <option value="Entity">Entity</option> */}
              <option value="Telemarketer">Telemarketer</option>
            </select>
          </div>

          <div className="d-flex align-items-center mb-3 col-md-6">
            <div className="col-md-5">
              TM ID (DLT Registration ID) :{" "}
            </div>
            <div className="searchBar col-md-7">
              <input
                id="searchQueryInput"
                type="text"
                name="searchQueryInput"
                placeholder="Enter TM ID"
                style={{ background: "#F8F8F9" }}
                defaultValue={id}
                onChange={(e) => setId(e.target.value)}
              />
              {/* <button
                id="searchQuerySubmit"
                type="submit"
                name="searchQuerySubmit"
              >
                <span>
                  <svg
                    style={{ width: "24px", height: "24px" }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#8153A2"
                      d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                    />
                  </svg>
                </span>
              </button> */}
            </div>
          </div>

          <span className="d-flex justify-content-center">
            <button
              className="login_btn cursor"
              style={{ width: "100%" }}
              onClick={() => {
                viewDetail();
              }}
            >
              View Details
            </button>
          </span>
        </div>

        {viewDetails === true ? (
          <div className="binding_col mt-4">
            <table className="withBorder">
              <thead>
                <tr>
                  <td><b>Registration Type :</b></td>
                  <td>{containData.eclass}</td>
                </tr>

                <tr>
                  <td><b>Entity Type :</b></td>
                  <td>
                    {containData.etype === "G" && <span>Goverment</span>}
                    {containData.etype === "P" && <span >Private</span>}
                    {containData.etype === "U" && <span >Public</span>}
                    {containData.etype === "O" && <span >Others</span>}
                    {containData.etype === "S" && <span >SEBI</span>}
                  </td>
                </tr>

                <tr>
                  <td><b>Name of Organisation :</b></td>
                  <td>{containData.name}</td>
                </tr>
              </thead>
            </table>

            <div className="d-flex flex-column align-items-center m-4">
            <p className="mb-3" style={{color:"red"}}>Upload Letter of Authorization *</p>
              <input type="file" style={{ background: "#F8F8F9" }} onChange={(e) => fileInput(e)} />

              <button
                className="login_btn cursor mt-4"
                style={{ width: "40%" }}
                onClick={() => {
                  submit();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </PrivateLayout>
  );
};

export default BindingPartner;
