import React, { useState, useEffect } from "react";
import * as imgs from "../../Assets/images";
import {
  useAdminDashboardMutation,
  useAdminOperatorSMSMutation,
} from "../../Services/services";
import Loaders from "../../Assets/loaders/loaders";
import { AdminPrivateLayout } from "../../layouts/AdminPrivateLayout";
import Counter from "../../Components/counter";
import { useNavigate } from "react-router-dom";
const AdminDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // table data API
  let [summaryRender] = useAdminDashboardMutation();
  let [getOp] = useAdminOperatorSMSMutation();

  const [dashboardSummary, setDashboardSummary] = useState({
    entity: 0,
    entityActive: 0,
    entityBlock: 0,
    telem: 0,
    telemActive: 0,
    telemBlock: 0,
    Url: 0,
    whitelistUrl: 0,
    blacklistUrl: 0,
    totalIp: 0,
    whitelistedIp: 0,
    blacklistedIp: 0,
    callback: 0,
  });
  const [sms, setSms] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("type") !== "ADMIN") {
      navigate("/admin-login");
    } else {
      const fetchSummaryData = async () => {
        try {
          const summaryResponse = await summaryRender();
          handleSummaryResponse(summaryResponse);
        } catch (error) {
          setIsLoading(false);
        }
      };
  
      const handleSummaryResponse = async (response) => {
        if (response?.data?.statusCode === 200) {
          try {
            const opResponse = await getOp();
            handleOpResponse(opResponse);
          } catch (error) {
            setIsLoading(false);
          }
          updateDashboardSummary(response.data.data);
        } else if (response.error.data.statusCode > 300 || response.data.statusCode > 300) {
          setIsLoading(false);
        }
      };
  
      const handleOpResponse = (response) => {
        if (response?.data?.statusCode === 200) {
          setSms(response.data.data[0].total_count);
        } else if (response.error.data.statusCode > 300 || response.data.statusCode > 300) {
          setIsLoading(false);
        }
      };
  
      const updateDashboardSummary = (data) => {
        setDashboardSummary({
          ...dashboardSummary,
          entity: data.entityCount,
          entityActive: data.entityActiveCount,
          entityBlock: data.entityDeactiveCount,
          telem: data.telemarketerCount,
          telemActive: data.telemarketerActiveCount,
          telemBlock: data.telemarketerDeactiveCount,
          Url: data.totalURLCount,
          whitelistUrl: data.whitelistedURLCount,
          blacklistUrl: data.blacklistedURLCount,
          totalIp: data.totalIPCount,
          whitelistedIp: data.whitelistedIPCount,
          blacklistedIp: data.blacklistedIPCount,
          callback: data.totalCallbackCount,
        });
        setIsLoading(false);
      };
  
      fetchSummaryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  return (
    <AdminPrivateLayout>
      {isLoading && <Loaders />}
      <div className="data_layout_2">
        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Total Enterprises</p>
              <h1 className="headCount">
                <Counter number={dashboardSummary.entity} />
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Active"
            >
              <span className="material-symbols-outlined">check_circle</span>
              <p>
                <Counter number={dashboardSummary.entityActive} />
              </p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Inactive"
            >
              <span className="material-symbols-rounded">cancel</span>
              <p>
                <Counter number={dashboardSummary.entityBlock} />
              </p>
            </span>
          </div>
        </div>

        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Total Telemarketers</p>
              <h1 className="headCount">
                <Counter number={dashboardSummary.telem} />
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Active"
            >
              <span className="material-symbols-outlined">check_circle</span>
              <p>
                <Counter number={dashboardSummary.telemActive} />
              </p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Inactive"
            >
              <span className="material-symbols-rounded">cancel</span>
              <p>
                <Counter number={dashboardSummary.telemBlock} />
              </p>
            </span>
          </div>
        </div>
        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Registered URLs</p>
              <h1 className="headCount">
                <Counter number={dashboardSummary.Url} />
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Whitelisted URLs"
            >
              <span className="material-symbols-outlined">add_circle</span>
              <p>
                <Counter number={dashboardSummary.whitelistUrl} />
              </p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Blacklisted URLs"
            >
              <span className="material-symbols-rounded">block</span>
              <p>
                <Counter number={dashboardSummary.blacklistUrl} />
              </p>
            </span>
          </div>
        </div>
      </div>

      <div className="data_layout_2">
        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Total IP</p>
              <h1 className="headCount">
                <Counter number={dashboardSummary.totalIp} />
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Whitelisted IPs"
            >
              <span className="material-symbols-outlined">add_circle</span>
              <p>
                <Counter number={dashboardSummary.whitelistedIp} />
              </p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Blacklisted IPs"
            >
              <span className="material-symbols-rounded">block</span>
              <p>
                <Counter number={dashboardSummary.blacklistedIp} />
              </p>
            </span>
          </div>
        </div>

        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">Callback Numbers</p>
              <h1 className="headCount">
                <Counter number={dashboardSummary.callback} />
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          {/* <div className="d-flex justify-content-end">
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Whitelisted Numbers"
            >
              <span className="material-symbols-rounded">add_circle</span>
              <p>
                <Counter number={99} />
              </p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Blacklisted Numbers"
            >
              <span className="material-symbols-rounded">block</span>
              <p>
                <Counter number={99} />
              </p>
            </span>
          </div> */}
        </div>

        <div className="data_box">
          <div className="d-flex justify-content-between">
            <span className="d-grid">
              <p className="subHead">SMS Sent</p>
              <h1 className="headCount">
                <Counter number={sms} />
              </h1>
            </span>
            <span className="img_box">
              <img src={imgs.icon_1} alt="icon" />
            </span>
          </div>
          {/* <div className="d-flex justify-content-end">
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Whitelisted URLs"
            >
              <span className="material-symbols-rounded">add_circle</span>
              <p>
                <Counter number={99} />
              </p>
            </span>
            <span
              className="subData"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Blacklisted URLs"
            >
              <span className="material-symbols-rounded">block</span>
              <p>
                <Counter number={99} />
              </p>
            </span>
          </div> */}
        </div>
      </div>
    </AdminPrivateLayout>
  );
};

export default AdminDashboard;
