import React, { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { useTmRejectMutation } from "../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../Assets/loaders/loaders";
import PropTypes from 'prop-types';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "60px",
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "30px",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "44px",
    border: "2px solid #8153A2",
};

export default function RejectModal({ open, onClose, id }) {
    const navigate = useNavigate();
    const [rejectSubmit] = useTmRejectMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [remark, setRemark] = useState();


    const postData = {
        tid:id[0],
        peid: id[1],
        ref: id[2],
        sts: "R",
        remarks:remark
    }

    // Reject Entity
    const rejectEntity = (e) => {
        if (remark === ""||remark === undefined){
            toast.error("Please provide any Remarks!");
        } else{
            rejectSubmit(postData)
            .then((success) => {
                if (success?.data?.statusCode === 200) {
                    setIsLoading(false);
                    toast.success(success.data.message);
                    window.location.reload()
                    
                } else if (success?.data?.statusCode > 300) {
                    toast.error(success?.data?.message);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error.message);
            });
        }
    };
    const handleCloseModal = (e) => {
        if (e.target === e.currentTarget) {
            if (localStorage.getItem("type") === "Entity") {
                navigate("/entitymanage-route");
            } else if (localStorage.getItem("type") === "Telemarketer") {
                navigate("/manage-route");
            }
            onClose();
        }
    };

      // sets the filter - type of URL
      let handleInputChanges = (e) => {
        setRemark(e.target.value)
    };

    if (!open) return null;
    return (
        <Fragment>
            {isLoading && <Loaders />}
            <Modal
                open={open}
                onClose={(e) => handleCloseModal(e)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps = {{
                    onClick: null, // Prevents closing when clicking outside the Modal
                    }}
            >
                <Box sx={style}>
                    <h1 className="modal-head">Are you sure you want to Reject the Entity</h1>
                    <input
                        type="text"
                        className="inputURL"
                        placeholder="Enter Remarks"
                        // defaultValue={data.url}
                        onChange={(e) => handleInputChanges(e)}
                    />

                    <button className="login_btn cursor" onClick={() => rejectEntity()}>
                        Reject
                    </button>
                    <button
                        className="cancel_btn cursor"
                        onClick={(e) => handleCloseModal(e)}
                    >
                        Cancel
                    </button>
                </Box>
            </Modal>
        </Fragment>
    );
}

// Add prop types validation
RejectModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    id: PropTypes.array.isRequired, // Assuming 'id' is an array
};
