import React, { useState, useEffect } from "react";
import {
  useAdminUrlListMutation
} from "../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../../Assets/loaders/loaders";
import { AdminPrivateLayout } from "../../layouts/AdminPrivateLayout";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
const AdminURLDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // const [openModal, setOpenModal] = useState(false);
  let [urls, setUrls] = useState([]);

  let [getRegList] = useAdminUrlListMutation();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("type") !== "ADMIN") {
      navigate("/admin-login")
    } else
      handleDomains();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleDomains = () => {

    setIsLoading(true);
    getRegList()
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          setUrls(success.data.data.urlRegistationList);
        } else if (success.error.data.statusCode > 300) {
          setIsLoading(false);
        } else if (success.data.statusCode > 300) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  const handleMouse = (e, type, rowId) => {
    if (e.type === "mouseenter") {
      setIsHovered(`${type}-${rowId}`);
    } else {
      setIsHovered("");
    }
  };

  let handleTableRow = (row, index) => {
    const rowId = row._id; // Assuming _id is unique for each row
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row._id}</td>
        <td>{row.pename}</td>
        <td
          onMouseEnter={(e) => handleMouse(e, "white", rowId)}
          onMouseLeave={(e) => handleMouse(e, "white", rowId)}
          id={`whitelisted-${rowId}`}
        >
          {isHovered === `white-${rowId}` ? (
            <Link to={`/admin-url/${"whitelisted"}/${rowId}`}>
              <button className="view_btn">View more...</button>
            </Link>
          ) : (
            row.whitelistCount
          )}
        </td>
        <td
          onMouseEnter={(e) => handleMouse(e, "black", rowId)}
          onMouseLeave={(e) => handleMouse(e, "black", rowId)}
          id={`blacklisted-${rowId}`}
        >
          {isHovered === `black-${rowId}` ? (
            <Link to={`/admin-url/${"blacklisted"}/${rowId}`}>
              <button className="view_btn">View more...</button>
            </Link>
          ) : (
            row.blacklistCount
          )}
        </td>
        <td>{row.totalCount}</td>
      </tr>
    );
  };


  return (
    <AdminPrivateLayout>
      {isLoading && <Loaders />}

      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <span className="top_border_vlt">
          <p>URL Details List</p>
        </span>
        <div className="table_fixhead max">
          <table>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Entity ID</th>
                <th>Entity Name</th>
                <th>Whitelisted URLs</th>
                <th>Blacklisted URLs</th>
                <th>Total URLs</th>
              </tr>
            </thead>
            <tbody>
              {urls.length > 0 ? (
                urls.map((row, index) => handleTableRow(row, index))
              ) : (
                <tr>
                  <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </AdminPrivateLayout>
  );
};

export default AdminURLDetail;
