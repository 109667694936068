export const Sidebardata = [
  {
    id: 1,
    icon: <span className="material-symbols-outlined">dashboard</span>,
    heading: "Dashboard",
    link: "/admin-dashboard",
  },
  {
    id: 2,
    icon: <span className="material-symbols-outlined">link</span>,
    heading: "URL Details",
    link: "/admin-url",
  },
  {
    id: 3,
    icon: <span className="material-symbols-outlined">edit_location_alt</span>,
    heading: "IP Details",
    link: "/admin-ip",
  },
  {
    id: 4,
    icon: <span className="material-symbols-outlined">phone_callback</span>,
    heading: "Callback Number Details",
    link: "/admin-callback",
  },
  {
    id: 5,
    icon: <span className="material-symbols-outlined">patient_list</span>,
    heading: "Entity Details",
    link: "/admin-pe-details",
  },
  {
    id: 6,
    icon: <span className="material-symbols-outlined">badge</span>,
    heading: "Telemarketer Details",
    link: "/admin-tm-details",
  },
  {
    id: 7,
    icon: <span className="material-symbols-outlined">tenancy</span>,
    heading: "Route Details",
    link: "/admin-route-details",
  },
  {
    id: 8,
    icon: <span className="material-symbols-outlined">send</span>,
    heading: "Send SMS",
    link: "/admin-send-sms",
  },
  {
    id: 9,
    icon: <span className="material-symbols-outlined">sms</span>,
    heading: "SMS Report",
    link: "/admin-sms-reports",
  },
];
