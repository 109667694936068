import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as imgs from "../../Assets/images";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useAdminLoginMutation,
  useAdminViewProfileMutation,
} from "../../Services/services";
import { useDispatch } from "react-redux";
import {
  login as logining,
  logout,
} from "../../features/login/adminAuthSlice";

const AdminLogin = () => {
  const navigate = useNavigate();
  const data = { email: "", password: "" };
  const [value, setValue] = useState(data);
  const [login] = useAdminLoginMutation();
  const [profile] = useAdminViewProfileMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
    dispatch(logout());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleData = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const loginData = {
    email: value.email,
    password: value.password,
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    login(loginData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          dispatch(logining(success.data.data.token));

          profile().then((success) => {
            if (success?.data?.statusCode === 200) {
              localStorage.setItem("email", success.data.data.email);
              localStorage.setItem("time", new Date());
              localStorage.setItem("id", success.data.data._id);
              localStorage.setItem("type", success.data.data.userType);
              localStorage.setItem("refNo", "");
              localStorage.setItem("name", success.data.data.name);
              toast.success("Login Successfull", { autoClose: 2000 });
              navigate("/admin-dashboard");
            } else if (success?.data?.statusCode > 300) {
              toast.error(success?.data?.message);
            } else if (success.error?.data?.statusCode > 300) {
              toast.error(success.error?.data?.message);
            }
          });
        } else if (success?.data?.statusCode > 300) {
          toast.error(success?.data?.message);
        } else if (success.error?.data?.statusCode > 300) {
          toast.error(success.error?.data?.message);
        }
      })
      .catch((error) => {
      });
  };

  return (
    <div>
      <div className="login_window">
        <div className="login text-center">
        <img src={imgs.mainLogo} alt="logo" />

          <h4>Login as Admin</h4>
          <input
            type="email"
            className="inputbox"
            placeholder="Enter Email"
            autoComplete="none"
            name="email"
            value={value.email}
            onChange={handleData}
          />
          <input
            type="password"
            className="inputbox"
            placeholder="Enter Password"
            autoComplete="none"
            name="password"
            value={value.password}
            onChange={handleData}
          />

          <button className="login_btn cursor" onClick={(e) => handleSubmit(e)}>
            Login
          </button>
          <button className="cancel_btn cursor" onClick={() => (navigate("/"))}>
            Back
          </button>
          <p className="m-0">
            A secured SMS Traceability platform to manage your Commercial
            communication nodes.
          </p>
        </div>
        <div className="text-center">
          <div className="d-flex mb-4 justify-content-center">
            <div className="me-5">
              <b>Powered By : </b>
              <img width="150px" src={imgs.teledgerslogo} alt="logo" />
            </div>
            <div className="">
              <b>Operator Partner : </b>
              <img height="56px" src={imgs.smartPinglogo} alt="logo" />
            </div>
          </div>
          <p>
            “Complies with the direction from TRAI dated 16th February 2023 to
            ensure traceability of supply chain
            <br />
            between principal entities and the Telemarketers involved in the SMS
            delivery process”
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
