import React, { useState, useEffect } from "react";
import {
  useAdminPeListMutation,
} from "../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../../Assets/loaders/loaders";
import { AdminPrivateLayout } from "../../layouts/AdminPrivateLayout";
import { useNavigate } from "react-router-dom";
const AdminEntityDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let [urls, setUrls] = useState([]);
  let [getList] = useAdminPeListMutation();

  useEffect(() => {
    if (localStorage.getItem("type") !== "ADMIN") {
      navigate("/admin-login")
    } else
      handleDomains();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleDomains = () => {

    setIsLoading(true);
    getList()
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          setUrls(success.data.data.entity);
        } else if (success.error.data.statusCode > 300) {
          setIsLoading(false);
        } else if (success.data.statusCode > 300) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  let handleTableRow = (row, index) => {
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row.id}</td>
        <td>{row.name}</td>
        <td>{new Date(row.cts * 1000).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false }).replace(/,/, '')}</td>
        {/* <td
          onMouseEnter={(e) => handleMouse(e, "white", rowId)}
          onMouseLeave={(e) => handleMouse(e, "white", rowId)}
          id={`whitelisted-${rowId}`}
        >
          {isHovered === `white-${rowId}` ? (
            <Link to={`/admin-pe-details/${rowId}`}>
              <button className="view_btn">View more...</button>
            </Link>
          ) : (
            row.urlMappingCount
          )}
        </td> */}
        <td>
          {row.sts === "P" && <span>Pending</span>}
          {row.sts === "A" && <span style={{ color: "#30ad23" }}>Active</span>}
          {row.sts === "R" && <span >Rejected</span>}
          {row.sts === "BK" && <span >Blocked</span>}
          {row.sts === "B" && <span >Blacklisted</span>}
          {row.sts === "I" && <span >Inactive</span>}
        </td>
      </tr>
    );
  };
  return (
    <AdminPrivateLayout>
      {isLoading && <Loaders />}

      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <span className="top_border_vlt">
          <p>Entity Details List</p>
        </span>
        <div className="table_fixhead max">
          <table>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Entity ID</th>
                <th>Entity Name</th>
                <th>Entity Registration Date</th>
                {/* <th>Entity Mapped URLs</th> */}
                <th>Entity Status</th>
              </tr>
            </thead>
            <tbody>
              {urls.length > 0 ? (
                urls.map((row, index) => handleTableRow(row, index))
              ) : (
                <tr>
                  <td colSpan="100%" style={{ textAlign: 'center', paddingTop: '200px', fontWeight: 'bold' }}>No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </AdminPrivateLayout>
  );
};

export default AdminEntityDetail;
