import React, { useState, useEffect } from "react";
import { useAdminListCallbackMutation } from "../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../../Assets/loaders/loaders";
import { AdminPrivateLayout } from "../../layouts/AdminPrivateLayout";
import { useNavigate, useParams } from "react-router-dom";

const AdminCallbackInside = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const [ipList] = useAdminListCallbackMutation();
  const navigate = useNavigate();

  let [urls, setUrls] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("type") !== "ADMIN") {
      navigate("/admin-login")
    } else
      handleDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleDomains = () => {
    let postData = {
      peid: id,
      number: "",
    };
    setIsLoading(true);
    ipList(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          setUrls(success.data.data.callbackList);
        } else if (success.error.data.statusCode > 300) {
          setIsLoading(false);
        } else if (success.data.statusCode > 300) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  let handleTableRow = (row, index) => {
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row.number}</td>
        <td>{new Date(row.createdAt).toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} {new Date(row.createdAt).getFullYear()}, {new Date(row.createdAt).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
        <td>{new Date(row.updatedAt).toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} {new Date(row.updatedAt).getFullYear()}, {new Date(row.updatedAt).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
        {/* <td>
          {row.status === "ACTIVE" ? <span style={{ color: "#30ad23" }}>ACTIVE</span> : <span style={{ color: "#ed1c36" }}>BLOCK</span>}
        </td> */}
      </tr>
    );
  };


  const goBack = () => {
    navigate("/admin-callback");
  };
  return (
    <AdminPrivateLayout>
      {isLoading && <Loaders />}

      {/* ------------------------------------data table----------------------------------- */}

      <div className="data_layout_4 ">
        <div className="top_border_vlt d-flex justify-content-center">
          <h5 className="ms-auto m-0">Callback Number Details</h5>
          <span
            className="material-symbols-outlined cursor ms-auto"
            onClick={() => goBack()} // Handle click event
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                goBack(); // Handle Enter or Space key event
              }
            }}
            role="button" // Make the span an interactive element
            aria-label="Go back" // Provide context for screen readers
            tabIndex={0} // Make it focusable
          >
            arrow_back
          </span>
        </div>

        <div className="table_fixhead max">
          <table>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Phone Number</th>
                <th>Created At</th>
                <th>Updated At</th>
                {/* <th>Number Status</th> */}
              </tr>
            </thead>
            <tbody>
              {urls.length > 0 ? (
                urls.map((row, index) => handleTableRow(row, index))
              ) : (
                <tr>
                  <td
                    colSpan="100%"
                    style={{
                      textAlign: "center",
                      paddingTop: "200px",
                      fontWeight: "bold",
                    }}
                  >
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </AdminPrivateLayout>
  );
};

export default AdminCallbackInside;
