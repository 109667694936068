// Loader.jsx
import React from 'react';
import './loader.css';

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="verifying-text">Verifying
      <g className="dot-pulse"></g>
      <g className="dot-pulse"></g>
      <g className="dot-pulse"></g>
      </div>
    </div>
  );
};

export default Loader;
