import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import { useNavigate } from "react-router-dom";
import * as imgs from "../Assets/images";
import { useAddURLRegMutation, useUrlVerifyMutation } from "../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../Assets/loaders/verifyLoader";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "560px",
  padding: "60px",
  display: "inline-flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "30px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "44px",
  border: "2px solid #8153A2",
};

export default function CustomModal({ open, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);


  // const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [type, setType] = useState("whitelisted");
  const [part, setPart] = useState("fixed");
  const [url, setUrl] = useState("");
  const [addURL] = useAddURLRegMutation();
  const [verifyURL] = useUrlVerifyMutation();
  const [urlVerify, setUrlVerify] = useState(false);
  const [contentURL, setContentURL] = useState("");
  const [phisingStatus, setPhisingStatus] = useState();
  const [phisingCode, setPhisingCode] = useState("");
  const [isVerifyLoading, setIsVerifyLoading] = useState(false)
  const [protocol, setProtocol] = useState("https");
  const [domain, setDomain] = useState("");
  const [tld, setTld] = useState(".com");
  const [customTld, setCustomTld] = useState("");

  useEffect(() => {

  }, [url, domain, protocol])

  const handleProtocolChange = (e) => {
    setUrlVerify(false)
    setProtocol(e.target.value);
  };

  const handleDomainChange = (e) => {
    setUrlVerify(false)
    const stringToCheck = e.target.value;

    const pattern = /:\/\/|https|http|www\./;

    const containsSubstring = pattern.test(stringToCheck);

    if (containsSubstring === true) {
      const domainRegex = /^(?:(https?):\/\/)?(?:www\.)?([a-zA-Z0-9_-]+)\.(\w+)(?:\/.*)?$/;
      const match = e.target.value.match(domainRegex);
      if (match) {
        const extractScheme = match[1]
        const extractedDomain = match[2];
        const extractedTLD = match[3];
        setProtocol(extractScheme)
        setDomain(extractedDomain);
        setTld("others")
        setCustomTld("." + extractedTLD)
      } else {
        toast.error("Please provide valid URL")
      }

    } else {
      setDomain(e.target.value);
    }
  }

  const handleTldChange = (e) => {
    setUrlVerify(false)
    const selectedTld = e.target.value;
    setTld(selectedTld);

  };

  const handleCustomTldChange = (e) => {
    setUrlVerify(false)
    setCustomTld(e.target.value);
  };




  // sends URL through API
  const submitURL = () => {
    const selectedTld = tld === "others" ? customTld : tld;
    const url = `${protocol}://${domain}${selectedTld}/`;
 
    if (protocol === "" || domain === "" || selectedTld === "" || url === "") {
      toast.error("Please provide URL")
    }
    else if (urlVerify === false) {
      toast.error("Please Verify your URL.")
    }
    
    else {
      const urlData = {
        urlType: type,
        part: "fixed",
        url: url,
        url_status: phisingStatus,
        code: phisingCode,
      };
      setIsLoading(true);
      addURL(urlData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            toast.success(success.data.message, { autoClose: 1000 });
            setIsLoading(false);
            setOpenSecondModal(true);
            // onClose();
          } else if (success?.data?.statusCode > 300) {
            setIsLoading(false);
            toast.error(success?.data?.message);
          }
          else if (success?.error?.status > 300) {
            setIsLoading(false);
            toast.error(success?.error?.data?.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.message);
        });
    }
  };

  const handleCloseModal = () => {
    setActive(false)
    setType("whitelisted")
    setPart("")
    setUrl("")
    setDomain("")
    setCustomTld("")
    setTld(".com")
    setOpenSecondModal(false);
    onClose();
    setUrlVerify(false)
    setContentURL("")
    setPhisingCode("")
    setPhisingStatus("")
  };

  // verify URL
  const checkVerify = (e) => {

    setUrlVerify(e)
    setContentURL("")
    setPhisingCode("")
    setPhisingStatus("")
    setIsVerifyLoading(true)
    if (e === false) {
      setIsVerifyLoading(false)
      toast.error("Verify your URL", { autoClose: 1000 })
    } else {
      const selectedTld = tld === "others" ? customTld : tld;
      const url = `${protocol}://${domain}${selectedTld}/`;
      const urlData = {
        "url": url
      }
      setIsVerifyLoading(true)
      verifyURL(urlData)
        .then((success) => {
          setIsVerifyLoading(false)
          setContentURL(success.data.data.url)
          setPhisingStatus(success.data.data.status)
          setPhisingCode(success.data.data.code)
          setIsLoader(false);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  }



  if (!open) return null;
  return (
    <>
      {isLoader && <Loaders />}
      {!isLoading ? (
        <Modal

          open={open}
          onClose={(e) => handleCloseModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            onClick: null, // Prevents closing when clicking outside the Modal
          }}
        >
          {!openSecondModal ? (
            <Box sx={style}>
              <h1 className="modal-head">Add URL</h1>

              <span className="url_toggles">
                <button
                  value="whitelisted"
                  className={active === false ? " active url_btn" : "url_btn"}
                  onClick={(e) => {
                    setActive(false);
                    setType(e.target.value);
                  }}
                >
                  Whitelisted URL
                </button>
                <button
                  value="blacklisted"
                  className={active === true ? "active url_btn " : "url_btn"}
                  onClick={(e) => {
                    setActive(true);
                    setType(e.target.value);
                  }}
                >
                  Blacklisted URL
                </button>
              </span>

              <select
                name="list"
                className="category_btn select_lg"
                value={part}
                // onChange={(e) => setPart(e.target.value)}
                id="selectBox"
                disabled
              >
                {/* <option value="none">
                  Choose URL Type
                </option> */}
                <option value="fixed">Fixed Part </option>
                {/* <option value="variable">Variable Part </option> */}
              </select>

              <div className="w-100 text-center">
                <select className="category_btn1" value={protocol} onChange={handleProtocolChange} >
                  <option value="https">https</option>
                  <option value="http">http</option>
                </select>

                <input
                  type="text"
                  className="inputURL1"
                  placeholder="Enter Domain Name"
                  value={domain}
                  maxLength={63}
                  onChange={handleDomainChange}
                  required
                  autoCorrect="false"
                />

                {tld === "others" ? (
                  <input
                    autoFocus
                    className="category_btn1"
                    type="text"
                    placeholder="TLD..."
                    value={customTld}
                    onChange={handleCustomTldChange}
                    autoCorrect="false"
                  />
                ) : (<select className="category_btn1" value={tld} onChange={handleTldChange}>
                  <option value=".com">.com</option>
                  <option value=".co">.co</option>
                  <option value=".in">.in</option>
                  <option value="others">Others</option>
                </select>)
                }
              </div>
              {/* <button className="login_btn cursor">Verify</button> */}
              {(domain !== "") &&
                <div className=" d-flex align-self-center flex-column mt-2">
                  <span className="enhancements d-flex align-self-center">
                    <input id="agreement" type="checkbox" onChange={(e) => checkVerify(e.target.checked)} checked={urlVerify === false ? false : true} />
                    <label htmlFor="agreement">Verify your URL.</label>
                  </span>
                  {(urlVerify !== false && phisingStatus === "green") && <div> <p style={{ color: "green" }}>{contentURL}</p>URL is <b>Verified</b> successfully and having status Code: {phisingCode}</div>}
                  {(urlVerify !== false && phisingStatus === "red") && <div> <p style={{ color: "red" }}>{contentURL}</p>URL is <b>Invalid</b> and having status Code: {phisingCode}</div>}
                  {(urlVerify !== false && phisingStatus === "grey") && <div> <p style={{ color: "grey" }}>{contentURL}</p>URL is <b>Invalid</b> and having status Code: {phisingCode}</div>}
                  {(urlVerify !== false && isVerifyLoading) && <div> <p style={{ color: "grey" }}>Waiting...</p></div>}

                </div>

              }

              <button className="login_btn cursor" disabled={!urlVerify || isVerifyLoading} onClick={() => submitURL()}>
                Send Request
              </button>
              <button
                className="cancel_btn cursor"
                onClick={(e) => handleCloseModal()}
              >
                Cancel
              </button>
            </Box>
          ) : (
            <Box sx={style}>
              <h1 className="modal-head">Add URL Request Sent Successfully</h1>
              <img src={imgs.modalBg} alt="done" />

              <p>
                Your URL: <span style={{ fontWeight: "100" }}>{url}</span> has been {type} with URL Type: <span style={{ fontWeight: "100" }}>{part}</span> successfully
              </p>
              <button
                className="cancel_btn cursor"

                onClick={(e) => handleCloseModal()}
              >
                Done
              </button>
            </Box>
          )}
        </Modal>) : (
        <Modal
          open={open}
          onClose={(e) => handleCloseModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            onClick: null, // Prevents closing when clicking outside the Modal
          }}
        >
          <Box sx={style}>
            <h1 className="modal-head">Add URL Request Sent Successfully</h1>
            <img src={imgs.modalBg} alt="done" />

            <p>
              {type === "whitelisted" ? "Whitelisted" : "Blacklisted"} URL Adding...
            </p>

          </Box>
        </Modal>
      )}
    </>
  );
}
