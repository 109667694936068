import React, { useEffect, useState } from "react";
import resCodes from "../../Components/responseCodes";
import {
  useAdminSendSMSMutation,
  useAdminMappingURLMutation,
  useAdminScrubSMSMutation,
} from "../../Services/services";
import "react-toastify/dist/ReactToastify.css";
import { AdminPrivateLayout } from "../../layouts/AdminPrivateLayout";
import Loaders from "../../Assets/loaders/loaders";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
const AdminSendSMS = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitRequest] = useAdminSendSMSMutation();
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const [code, setCode] = useState(0);

  let [mappingData, setMappingData] = useState({
    chain: "",
    privateKey: "",
    entityID: "",
    telemarketerID: "",
    sms: "",
    header: "",
    contactNumber: "",
    contentID: "",
  });
  const [getData] = useAdminMappingURLMutation();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (localStorage.getItem("type") !== "ADMIN") {
      navigate("/admin-login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingData, data, code]);

  let handleInputChanges = (e, state) => {
    let targetValue = e.target.value;
    setMappingData({ ...mappingData, [[state]]: targetValue });

    if (state === "contentID") {
      handleGetData(e);
    }
  };

  const handleGetData = (e) => {
    const postData = {
      templateId: e.target.value,
    };
    setIsLoading(true);
    getData(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setIsLoading(false);
          setData(success.data.data);
          mappingData.sms = success.data.data.msg;
          toast.success(success?.data?.message, { autoClose: 2000 });
        } else if (success?.data?.statusCode > 200) {
          setIsLoading(false);
        } else if (success?.error?.data.statusCode > 200) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  }; 

  const handleChange = (value) => {
    setPhone(value);
  };

  const submitPostData = {
    chain: mappingData.chain,
    prvtkey: mappingData.privateKey,
    peid: data.peid,
    tmid: mappingData.telemarketerID,
    Ip: "192.168.00.00",
    content: mappingData.sms,
    headerId: mappingData.header,
    contactNo: phone,
    contentId: mappingData.contentID,
    pename: data.ename,
    tmname: data.tname,
  };

  const submit = () => {
    const requiredFields = ["contentID", "telemarketerID", "header", "chain", "privateKey"];
    const missingField = requiredFields.find(field => !mappingData[field]);
    if (missingField) {
      toast.error(`Please provide ${missingField.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
      return;
    }

    if (!phone || !phone.match(/((\b(0[ -]*)*|((91)?))(\d{10})\b)/)) {
      toast.error("Please provide a valid contact number.");
      return;
    } else {
      setIsLoading(true);
      submitRequest(submitPostData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            toast.success(success?.data?.message, { autoClose: 2000 });
            setTimeout(() => {
              setIsLoading(false);
              window.location.reload();
            }, 2000);
          } else if (success?.data?.statusCode > 200) {
            setIsLoading(false);
            toast.error(success?.data?.message);
          } else if (success?.error?.data.statusCode > 200) {
            setIsLoading(false);
            toast.error(success?.error?.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.message);
        });
    }
  };

  const bringMessage = (code) => {
    if (code >= 2001 && code <= 2007){
      toast.success("Message sent successfully", { autoClose: 2000 });
    } else if (resCodes && code in resCodes) {
      toast.error(resCodes[code], { autoClose: 2000 });
    }
    else toast.error("Something went wrong", { autoClose: 2000 });
  };
  const [scrub] = useAdminScrubSMSMutation(); 
  const postScrub = {
    type:"",
    smsId:"",
    encodingType:"",
    commType:"",
    userId:"",
    chain: mappingData.chain,
    prvtkey: mappingData.privateKey,
    peid: data.peid,
    tmid: mappingData.telemarketerID,
    Ip: "192.168.00.00",
    contentId: mappingData.contentID,
    content: mappingData.sms,
    headerId: mappingData.header,
    pename: "",
    tmname: "",
    contactNo: phone,
    msgId:""
  }
  const scrubbing = () => {
    if (mappingData.contentID === "") {
      toast.error("Please provide Content ID");
    } else if (mappingData.telemarketerID === "") {
      toast.error("Please provide Telemarketer ID");
    } else if (mappingData.header === "") {
      toast.error("Please provide Header");
    } else if (mappingData.chain === "") {
      toast.error("Please provide Chain");
    } else if (mappingData.privateKey === "") {
      toast.error("Please provide Private Key");
    } else if (
      phone === "" ||
      phone === null ||
      !phone.match(/((\b(0[ -]*)*|((91)?))(\d{10})\b)/) === true
    ) {
      toast.error("Please provide contact no.");
    } else if (mappingData.sms === "") {
      toast.error("Please provide sms");
    } else {
      setIsLoading(true);
      scrub(postScrub)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setCode(success?.data?.data);
            bringMessage(success?.data?.data);
            setTimeout(() => {
              setIsLoading(false);
              // window.location.reload();
            }, 2000);
          } else if (success?.data?.statusCode > 200) {
            setIsLoading(false);
            toast.error(success?.data?.message);
          } else if (success?.error?.data.statusCode > 200) {
            setIsLoading(false);
            toast.error(success?.error?.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.message);
        });
    }
  };

  return (
    <AdminPrivateLayout>
      {isLoading && <Loaders />}
      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <div className="top_border_vlt">
          <h5 className="m-0">Send SMS</h5>
        </div>

        <div className="binding_col mt-5">
          <div className="d-flex align-items-center mb-3 col-md-6">
            <div className="col-md-4">Content ID :</div>
            <div className="col">
              <input
                id="searchQueryInput"
                type="text"
                name=""
                placeholder="Enter Content ID"
                style={{ background: "#EEF2F6" }}
                onChange={(e) => handleInputChanges(e, "contentID")}
              />
            </div>
          </div>

          <div className="d-flex align-items-center mb-3 col-md-6">
            <div className="col-md-4">Entity ID :</div>
            <div className="col">
              <input
                id="searchQueryInput"
                type="text"
                name=""
                disabled
                placeholder="Enter Entity ID"
                style={{ background: "#EEF2F6" }}
                defaultValue={data.peid === undefined ? "" : data.peid}
                onChange={(e) => handleInputChanges(e, "entityID")}
              />
            </div>
          </div>
          <div className="d-flex align-items-center mb-3 col-md-6">
            <div className="col-md-4">Telemarketer ID :</div>
            <div className="col">
              <input
                id="searchQueryInput"
                type="text"
                name=""
                placeholder="Enter Telemarketer ID"
                style={{ background: "#EEF2F6" }}
                onChange={(e) => handleInputChanges(e, "telemarketerID")}
              />
            </div>
          </div>

          <div className="d-flex align-items-center mb-3 col-md-6">
            <div className="col-md-4">Header :</div>
            <div className="col">
              <input
                id="searchQueryInput"
                type="text"
                name=""
                placeholder="Enter Header"
                style={{ background: "#EEF2F6" }}
                onChange={(e) => handleInputChanges(e, "header")}
              />
            </div>
          </div>

          <div className="d-flex align-items-center mb-3 col-md-6">
            <div className="col-md-4">Chain :</div>
            <div className="col">
              <input
                id="searchQueryInput"
                type="text"
                name=""
                placeholder="Enter Chain"
                style={{ background: "#EEF2F6" }}
                onChange={(e) => handleInputChanges(e, "chain")}
              />
            </div>
          </div>

          <div className="d-flex align-items-center mb-3 col-md-6">
            <div className="col-md-4">Private Key :</div>
            <div className="col">
              <input
                id="searchQueryInput"
                type="text"
                name=""
                placeholder="Enter Private Key"
                style={{ background: "#EEF2F6" }}
                onChange={(e) => handleInputChanges(e, "privateKey")}
              />
            </div>
          </div>

          <div className="d-flex align-items-center mb-3 col-md-6">
            <div className="col-md-4">Contact Number :</div>
            <div className="col">
              <PhoneInput
                country={"in"}
                value={phone}
                disableDropdown
                disableCountryCode
                onlyCountries={["in"]}
                placeholder="99999-99999"
                inputProps={{
                  name: "phone",
                  required: true,
                }}
                isValid={(value) => {
                  if (!value) {
                    return true; // No validation error for empty value
                  } else if (
                    !value.match(/((\b(0[ -]*)*|((91)?))(\d{10})\b)/)
                  ) {
                    return "Invalid number";
                  } else if (value.match(/12345/)) {
                    return "Invalid number: cannot contain 12345";
                  } else {
                    return true;
                  }
                }}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="d-flex align-items-center mb-3 col-md-6">
            <div className="col-md-4">Type SMS :</div>
            <div className="col">
              <textarea
                id="searchQueryInput"
                type="text"
                name=""
                placeholder="Enter SMS"
                rows={3}
                style={{ background: "#EEF2F6", minHeight: "100px" }}
                defaultValue={
                  data.msg === undefined
                    ? (mappingData.sms = "")
                    : (mappingData.sms = data.msg)
                }
                onChange={(e) => handleInputChanges(e, "sms")}
              />
            </div>
          </div>

          <div className="d-flex mt-4">
            <button
              className="login_btn cursor "
              onClick={() => submit()}
            >
              Send
            </button>
            <button
              className="login_btn cursor ms-2"
              onClick={() => {
                scrubbing();
              }}
            >
              Scrub
            </button>
          </div>
        </div>
      </div>
    </AdminPrivateLayout>
  );
};

export default AdminSendSMS;
