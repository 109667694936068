export const Sidebardata = [
  {
    id: 1,
    icon: <span className="material-symbols-outlined">dashboard</span>,
    heading: "Dashboard",
    link: "/entitydashboard",
  },
  {
    id: 2,
    icon: <span className="material-symbols-outlined">tenancy</span>,
    heading: "Manage Route",
    link: "/entitymanage-route",
  },
  {
    id: 3,
    icon: <span className="material-symbols-outlined">link</span>,
    heading: "URL Registration",
    link: "/entityregistrations",
  },
  {
    id: 4,
    icon: <span className="material-symbols-outlined">account_tree</span>,
    heading: "URL Mapping",
    link: "/entitymapping",
  },
  {
    id: 5,
    icon: <span className="material-symbols-outlined">add_call</span>,
    heading: "Entity Callback Registration",
    link: "/entitycallback",
  },
  // {
  //   id: 6,
  //   icon: <span className="material-symbols-outlined">edit_location_alt</span>,
  //   heading: "Manage IP",
  //   link: "/entitymanageIp",
  // },
  
  {
    id: 7,
    icon: <span className="material-symbols-outlined">sms</span>,
    heading: "SMS Reports",
    link: "/entitysmsreport",
  },
  {
    id: 8,
    icon: <span className="material-symbols-outlined">feedback</span>,
    heading: "UCC Complaints",
    link: "/entitycomplaints",
  }

];
