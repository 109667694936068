import React, { useState } from "react";
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
import Modal from "@mui/material/Modal";
import * as imgs from "../Assets/images";
import { useEntityCreateCallbackMutation } from "../Services/services";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "560px",
  padding: "60px",
  display: "inline-flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "30px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "44px",
  border: "2px solid #8153A2",
};

export default function ModalCallback({ open, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [phone, setPhone] = useState("");

  const [numberType, setNumberType] = useState("phone");

  let [addCallback] = useEntityCreateCallbackMutation();

  const handleChange = (value) => {
    setPhone(value);
  };

  const submitURL = (e) => {
    let postData = {
      number: phone,
    };
    if (phone === "") {
      toast.error("Please add phone number for the Callback registration");
    }
    else if (
      numberType === "phone" && !phone.match(/((\b(0*[1-9][ -]*)*|((91)?))([1-9]\d{9})\b)/)
    ) {
      toast.error("Invalid Phone Number");
    }
    else if (
      numberType === "tollfree" && !phone.match(/^(\d{3,15})$/)
    ) {
      toast.error("Invalid TollFree/Telephone Number");
    } else {
      setIsLoading(true);
      addCallback(postData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setIsLoading(false);
            setOpenSecondModal(true);
            toast.success(success.data.message, { autoClose: 3000 });
          } else if (success.error.data.statusCode > 300) {
            toast.error(success.error.data.message);
            setIsLoading(false);
          } else if (success.data.statusCode > 300) {
            toast.error(success?.data?.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.message);
        });
    }
  };
  const handleCloseModal = (e) => {
    setPhone("");
    setNumberType("phone");
    setOpenSecondModal(false);
    onClose();
  };

  const isValidPhoneNumber = (value) => {
    if (!value) {
      return null; // No validation error for empty value
    } else if (!value.match(/((\b(0*[1-9][ -]*)*|((91)?))([1-9]\d{9})\b)/)) {
      return "Invalid number";
    } else if (value.match(/12345/)) {
      return "Invalid number: cannot contain 12345";
    } else {
      return null; // Value is valid
    }
  };

  if (!open) return null;
  return (
    <>
      {!isLoading ? (
        <Modal
          open={open}
          onClose={(e) => handleCloseModal(e)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            onClick: null, // Prevents closing when clicking outside the Modal
          }}
        >
          {!openSecondModal ? (
            <Box sx={style}>
              <h1 className="modal-head">Callback Number Registration</h1>
              <select
                name="list"
                className="category_btn select_lg"
                defaultValue="none"
                onChange={(e) => setNumberType(e.target.value)}
                id="selectBox"
              >
                <option value="phone">Phone Number</option>
                <option value="tollfree">Toll-Free/Telephone Number</option>
              </select>
              {numberType === "phone" && (
                <div className="input-group1">
                  <PhoneInput
                    country={"in"}
                    value={phone}
                    disableDropdown
                    disableCountryCode
                    onlyCountries={["in"]}
                    placeholder="99999-99999"
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    isValid={isValidPhoneNumber}
                    onChange={handleChange}
                  />
                </div>
              )}

              {numberType === "tollfree" && (
                <div className="input-group1 tollfree">
                  <PhoneInput
                    country={"in"}
                    value={phone}
                    disableDropdown
                    disableCountryCode
                    onlyCountries={["in"]}
                    masks={{ in: "..............." }}
                    placeholder="Enter your number"
                    inputProps={{
                      name: "phone",
                      required: true,
                      minLength: 3,
                      maxLength: 15, // Set maximum length to 15 digits
                    }}
                    isValid={(value) => {
                      if (!value) {
                        return true; // No validation error for empty value
                      } else if (!value.match(/^(\d{3,15})$/)) {
                        return "Invalid number";
                      } else {
                        return true;
                      }
                    }}
                    onChange={handleChange}
                  />
                </div>
              )}

              <button className="login_btn cursor" onClick={() => submitURL()}>
                Send Request
              </button>
              <button
                className="cancel_btn cursor"
                onClick={(e) => handleCloseModal(e)}
              >
                Cancel
              </button>
            </Box>
          ) : (
            <Box sx={style}>
              <h1 className="modal-head">
                Number Registered Submitted Successfully
              </h1>
              <img src={imgs.modalBg} alt="done" />
              <p style={{ textAlign: "center" }}>
                {phone} has been successfully registered for the Callback
              </p>
              <button
                className="cancel_btn cursor"
                onClick={(e) => handleCloseModal(e)}
              >
                Done
              </button>
            </Box>
          )}
        </Modal>
      ) : (
        <Modal
          open={open}
          onClose={(e) => handleCloseModal(e)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            onClick: null, // Prevents closing when clicking outside the Modal
          }}
        >
          <Box sx={style}>
            <h1 className="modal-head">
              Phone Number Registration for Callback
            </h1>
            <img src={imgs.modalBg} alt="done" />

            <p>Phone Number Registering...</p>
          </Box>
        </Modal>
      )}
    </>
  );
}

// Add prop validation for onClose

ModalCallback.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};