import React, { useState, useEffect } from "react";
import {
  useAdminPESMSMutation,
  useAdminTMSMSMutation,
  // useAdminOperatorSMSMutation,
} from "../../Services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from "../../Assets/loaders/loaders";
import { AdminPrivateLayout } from "../../layouts/AdminPrivateLayout";
import { useNavigate } from "react-router-dom";
const AdminSMSReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isToggle, setIsToggle] = useState("entity");
  const navigate = useNavigate();
  let [urls, setUrls] = useState([]);
  let [getPE] = useAdminPESMSMutation();
  let [getTM] = useAdminTMSMSMutation();

  useEffect(() => {
    if (localStorage.getItem("type") !== "ADMIN") {
      navigate("/admin-login");
    } else handleDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToggle]);

  let handleDomains = () => {
    setIsLoading(true);
    switch (isToggle) {
      case "entity":
        getPE()
          .then((success) => {
            if (success?.data?.statusCode === 200) {
              setIsLoading(false);
              setUrls(success.data.data);
            } else if (success.error.originalStatus > 300) {
              setIsLoading(false);
            } else if (success.data.statusCode > 300) {
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            toast.error(error.status);
          });
        break;
      case "telemarketer":
        getTM()
          .then((success) => {
            if (success?.data?.statusCode === 200) {
              setIsLoading(false);
              setUrls(success.data.data);
            } else if (success.error.data.statusCode > 300) {
              setIsLoading(false);
            } else if (success.data.statusCode > 300) {
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            toast.error(error.message);
          });
        break;
      // case "operator":
      //   getOp()
      //     .then((success) => {
      //       if (success?.data?.statusCode === 200) {
      //         setIsLoading(false);
      //         setUrls(success.data.data);
      //       } else if (success.error.data.statusCode > 300) {
      //         setIsLoading(false);
      //       } else if (success.data.statusCode > 300) {
      //         setIsLoading(false);
      //       }
      //     })
      //     .catch((error) => {
      //       setIsLoading(false);
      //       toast.error(error.message);
      //     });

      default:
        break;
    }
  };

  let handleTableRow = (row, index) => {
    return (
      <tr key={index}>
        <td>{++index}</td>
        <td>{row.entity_id || row.tm_id}</td>
        <td>{row.aparty}</td>
        <td>{row.total_count}</td>
      </tr>
    );
    // } else {
    //   return (
    //     <tr key={index}>
    //       <td>{++index}</td>
    //       <td>{row.operator}</td>
    //       <td>{row.totalCounts}</td>
    //     </tr>
    //   );
    // }
  };

  let toggle = (value) => {
    if (value === "White") {
      setIsToggle("entity");
    } else if (value === "Black") {
      setIsToggle("telemarketer");
    } else {
      setIsToggle("operator");
    }
  };

  return (
    <AdminPrivateLayout>
      {isLoading && <Loaders />}

      {/* ------------------------------------data table----------------------------------- */}
      <div className="data_layout_4">
        <div className="switch-wrapper">
          <input id="white" type="radio" name="switch" defaultChecked />
          <input id="black" type="radio" name="switch" />
          <input id="request" type="radio" name="switch" />
          <label
            htmlFor="white"
            onClick={() => toggle("White")}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                toggle("White");
              }
            }}
            className="curve_right"
            tabIndex="0"
            role="button"
          >
            Entity-wise SMS Report
          </label>
          <label
            htmlFor="black"
            onClick={() => toggle("Black")}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                toggle("Black");
              }
            }}
            className="curve_right"
            tabIndex="0"
            role="button"
          >
            Telemarketer-wise SMS Report
          </label>
          {/* <label
            htmlFor="request"
            onClick={() => toggle("Operator")}
            className="curve_right one_third_w"
          >
            Operator-wise SMS Report
          </label> */}
          <span className="highlighter"></span>
        </div>
        <div className="table_fixhead max">
          {isToggle === "entity" && (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Entity ID</th>
                  <th>Header</th>
                  <th>Total SMS</th>
                </tr>
              </thead>
              <tbody>
                {urls.length > 0 ? (
                  urls.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td
                      colSpan="100%"
                      style={{
                        textAlign: "center",
                        paddingTop: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          {isToggle === "telemarketer" && (
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Telemarketer ID</th>
                  <th>Header</th>
                  <th>Total SMS</th>
                </tr>
              </thead>
              <tbody>
                {urls.length > 0 ? (
                  urls.map((row, index) => handleTableRow(row, index))
                ) : (
                  <tr>
                    <td
                      colSpan="100%"
                      style={{
                        textAlign: "center",
                        paddingTop: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>

    </AdminPrivateLayout >
  );
};

export default AdminSMSReport;
